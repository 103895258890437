// @formatter:off
import {intl_int, intl_num, intl_num_2, intl_num_4} from './i18n-util';
import {I18nParametric, I18nSimpleKey} from './i18n';

const msgs = {
  '1990': '1990',
  '1991': '1991',
  '1992': '1992',
  '1993': '1993',
  '1994': '1994',
  '1995': '1995',
  '1996': '1996',
  '1997': '1997',
  '1998': '1998',
  '1999': '1999',
  '2000': '2000',
  '2001': '2001',
  '2002': '2002',
  '2003': '2003',
  '2004': '2004',
  '2005': '2005',
  '2006': '2006',
  '2007': '2007',
  '2008': '2008',
  '2009': '2009',
  '2010': '2010',
  '2011': '2011',
  '2012': '2012',
  '2013': '2013',
  '2014': '2014',
  '2015': '2015',
  '2016': '2016',
  '2017': '2017',
  '2018': '2018',
  '2019': '2019',
  '2020': '2020',
  '2021': '2021',
  '2022': '2022',
  '2023': '2023',
  '2024': '2024',
  '2025': '2025',
  '2026': '2026',
  '2027': '2027',
  '2028': '2028',
  '2029': '2029',
  '2030': '2030',
  '2031': '2031',
  '2032': '2032',
  '2033': '2033',
  '2034': '2034',
  '2035': '2035',
  'ARG': 'Argentyna',
  'AUS': 'Australia',
  'AUT': 'Austria',
  'Accept': 'Zaakceptować',
  'AcceptOrDecline': 'Przyjąć/spadek',
  'AddAnotherArea': 'Dodaj inny obszar',
  'AddAssignee': 'Dodaj osobę przypisaną',
  'AddAttachments': 'Dodaj załączniki',
  'AddCause': 'Dodaj przyczynę',
  'AddClaim': 'Dodaj roszczenie',
  'AddCost': 'Dodaj koszt',
  'AddCrop': 'Dodaj rodzaj uprawy',
  'AddEditSampleShape': 'Zaznacz zagrożony obszar na mapie',
  'AddEditor': 'Dodaj redaktora',
  'AddEditors': 'Dodaj edytorów',
  'AddEditorsOneGroup': 'Dodaj edytorów do pozycji tylko w jednym portfolio',
  'AddFarm': 'Dodaj gospodarstwo',
  'AddFarmInfo': 'Należy pamiętać, że aby dodać nową/dodatkową działkę do ISTNIEJĄCEGO gospodarstwa, należy najpierw wyszukać je według nazwy gospodarstwa lub numeru referencyjnego gospodarstwa w odpowiednich polach poniżej.',
  'AddField': 'Dodaj nową działkę',
  'AddFieldShape': 'Dodaj kontur działki',
  'AddFieldShapes': 'Dodaj kontur działek',
  'AddFieldsHarvests': 'Dodaj działki i zbiory',
  'AddFromFileManager': 'Dodaj z Menedżera plików',
  'AddFromPhotos': 'Dodaj ze zdjęć',
  'AddGuaranteedLossCause': 'Dodaj gwarantowaną przyczynę straty',
  'AddHarvest': 'Dodaj nowy zbiór',
  'AddLossCause': 'Dodaj przyczynę szkody',
  'AddNewDamage': 'Dodaj nowe obrażenia',
  'AddNewHarvest': 'Dodaj nowy zbiór',
  'AddNewHarvestFor': 'Utwórz nowy zbiór dla',
  'AddNewLoss': 'Dodaj nową stratę',
  'AddNewSubparcel': 'Dodaj nową poddziałkę',
  'AddOrEditFields': 'Dodaj/edytuj pola',
  'AddPhoto': 'Dodaj zdjęcie',
  'AddPhotoError': 'Nie udało się dodać zdjęcia',
  'AddPolicy': 'Dodaj nową polisę',
  'AddRecipient': 'Dodaj odbiorcę',
  'AddSample': 'Dodaj próbkę',
  'AddSamplePhoto': 'Dodaj zdjęcie do próbki',
  'AddSubplotId': 'Dodaj identyfikator wątku pobocznego',
  'AddUnguaranteedLossCause': 'Dodaj niegwarantowaną przyczynę straty',
  'AddUser': 'Dodaj użytkownika',
  'AddVarietyHarvest': 'Dodaj różnorodność',
  'AddedBy': 'Dodane przez',
  'AddedGroups': 'Dodane grupy',
  'AddedOn': 'Dodane',
  'AdditionalInfo': 'Dodatkowe informacje',
  'AdditionalQuestions': 'Pytania dodatkowe',
  'AdvancedOptions': 'Opcje zaawansowane',
  'AffectedArea': 'Zagrożony obszar',
  'AffectedCrop': 'Dotknięta uprawa',
  'AffectedCrops': 'Dotknięte uprawy',
  'AffectedCropsRequiredToDeclareLoss': 'Aby dodać zadeklarowane straty, musisz wybrać przynajmniej jedną dotkniętą uprawę.',
  'AffectedField': 'Zaatakowane pole',
  'All': 'Wszystko',
  'AllSynced': 'Wszystko zsynchronizowane',
  'AllTime': 'Cały okres',
  'AlsoInheritedFrom': 'Odziedziczony również po',
  'Amount': 'Kwota (zł)',
  'AppVersionDeprecated': 'Wersja aplikacji przestarzała',
  'AppVersionDeprecatedMessage': 'Twoja wersja aplikacji jest przestarzała. Aby uzyskać nową wersję, musisz odświeżyć stronę. Kliknij ODŚWIEŻ, aby odświeżyć stronę. Jeśli masz jakieś niezapisane dane, kliknij Anuluj i ręcznie wykonaj kopię zapasową danych, a następnie odśwież stronę.',
  'Area': 'Powierzchnia',
  'AreaCropTitle': 'Powierzchnia według rodzaju upraw',
  'Ascending': 'rosnąco',
  'Assign': 'Przydzielać',
  'AssignExistingFields': 'Przydziel istniejące pola',
  'AssignedLossAdjusters': 'Przydzieleni likwidatorzy strat',
  'AssignedTo': 'Przypisane do',
  'AssociatedEmails': 'Powiązane e-maile',
  'Attachment': 'Załącznik',
  'Attachments': 'Załączniki',
  'Average': 'Średnia',
  'AverageFieldArea': 'Średnia powierzchnia pola',
  'AvgBollsPerPlant': 'Średnia liczba torebek na roślinę',
  'AvgEarsPerPlant': 'Średnia liczba kłosów na roślinę',
  'AvgGrainsPerEar': 'Średnia liczba ziaren w kłosie',
  'AvgGrainsPerPod': 'Średnia liczba ziaren na strąk',
  'AvgGrainsPerSilique': 'Średnia liczba ziaren na łuszczynę',
  'AvgKernelsPerBoll': 'Średnia liczba ziaren w torebce',
  'AvgPodsPerShaft': 'Średnia liczba strąków na łodygę',
  'AvgSiliquesPerShaft': 'Średnia liczba łuszczyn na łodygę',
  'BEL': 'Belgia',
  'BRA': 'Brazylia',
  'Back': 'Wstecz',
  'BackToHome': 'Ekran główny',
  'Benchmark': 'Wartość bazowa',
  'Biomass': 'Biomasa',
  'BunchWeight': 'Średnia waga kiści winogron w gramach',
  'BunchesPerPlant': 'Średnia liczba kiści winogron na roślinę',
  'ByCrop': 'Według upraw',
  'ByField': 'Według pola',
  'ByFieldArea': 'Według obszaru pola',
  'ByFieldCount': 'Według liczby pól',
  'CAN': 'Kanada',
  'CHE': 'Szwajcaria',
  'CZE': 'Czechy',
  'Calculate': 'Oblicz',
  'CalculateAbbreviated': 'Kalk.',
  'Cancel': 'Anuluj',
  'Change': 'Zmień',
  'ChangeOverrideSampleMsg': 'Czy na pewno chcesz zmienić wartość zastąpienia dla tego przycięcia?',
  'ChangeUserAccessRights': 'Zmień prawa dostępu użytkownika',
  'CheckForCodepushUpdate': 'Sprawdź aktualizacje',
  'CirclesArea': 'Obszar Koła',
  'CirclesDiameter': 'Średnica koła',
  'CirclesRadius': 'Promień okręgu',
  'Claim': 'Claim',
  'ClaimNumber': 'Numer roszczenia',
  'ClaimRequests': 'Żądania roszczeń',
  'ClaimStatus_adjuster-accepted': 'Dostosowujący zaakceptowany',
  'ClaimStatus_assessment-in-progress': 'Ocena w toku',
  'ClaimStatus_awaiting-adjuster-acceptance': 'Oczekuje na akceptację rzeczoznawcy',
  'ClaimStatus_completed': 'Zakończony',
  'ClaimStatus_created-unassigned': 'Utworzono i nieprzypisano',
  'ClaimStatus_payment-proposal-sent': 'Propozycja płatności wysłana',
  'ClaimStatus_rejected': 'Odrzucony',
  'ClaimStatus_visit-date-defined': 'Ustalono datę wizyty',
  'ClaimStatus_visit-report-created': 'Utworzono raport z wizyty',
  'ClaimStatus_visit-samples-collected': 'Rozpoczęto wizytę',
  'Claims': 'Roszczenia',
  'Clear': 'Wyczyść',
  'ClearAll': 'Wyczyść wszystko',
  'Clearing': 'Czyszczenie',
  'ClickHereToAddFarmerEmail': 'Kliknij tutaj, aby dodać adres e-mail rolnika',
  'ClickUpload': 'Kliknij, aby przesłać',
  'Close': 'Zamknąć',
  'CloseVisit': 'Zakończ wizytę',
  'Closed': 'Zamknięte',
  'ClosedVisits': 'Wizyty zamknięte',
  'CloudCover': 'Chmura',
  'CloudCoverInfo': 'Ta kolumna pokazuje najciekawsze momenty, jeśli którykolwiek rok był zbyt pochmurny, aby można było dokonać wiarygodnego pomiaru produktywności (> 50% zasłoniętych tygodni w okresie szczytowym)',
  'CloudCoverWarning': 'Ostrzeżenie o zachmurzeniu',
  'CommentInfoWarning': 'Ostrzeżenie! Nie pojawia się w raportach.',
  'Comments': 'Komentarze',
  'CompassHeading': 'Kompas',
  'CompensableLosses': 'Straty podlegające kompensacji',
  'Condition': 'Stan',
  'Configuration': 'Konfiguracja',
  'Confirm': 'Potwierdzać',
  'Contact': 'Skontaktuj się z nami',
  'ContainsCopernicus': 'Zawiera zmodyfikowane dane z Copernicus Service (2017)',
  'ContinueWithErrorsAndWarnings': 'Kontynuować bez naprawiania błędów i ostrzeżeń?',
  'ConversionRatio': 'Współczynnik konwersji',
  'CopyCurrentHarvest': 'Skopiuj bieżące zbiory',
  'CopyHarvestFromPreviousYears': 'Skopiuj zbiory z poprzednich lat',
  'CopyLink': 'Kopiuj link',
  'CopySample': 'Kopiuj próbkę',
  'CopyVisit': 'Skopiuj wizytę',
  'CostType': 'Rodzaj kosztów',
  'Costs': 'Koszty (zł)',
  'CouldntDelete': 'Nie można usunąć tej pozycji. Aby to zrobić, musisz być w trybie online.',
  'CreateNewVisit': 'Utwórz nową wizytę',
  'CropCondition': 'Stan uprawy',
  'CropConditionBreakdown': 'Rozmieszczenie według stanu upraw',
  'CropCoverage': 'Pokrycie upraw',
  'CropCoverageInfo': 'Pokazuje procent pola pokrytego daną uprawą',
  'CropType-s': 'Rodzaj upraw/y',
  'CropVariety': 'Odmiana',
  'CropYieldAverageTitle': ' Szacowane plony (średnia)',
  'CurHarvest': 'Bieżące zbiory',
  'CurrentFilters': 'Aktualne Filtry',
  'CurrentVisit': 'Aktualna wizyta',
  'CurrentVisits': 'Aktualne wizyty',
  'Customer': 'Klient',
  'DEU': 'Niemcy',
  'DamagedFields': 'Uszkodzone pola',
  'DamagedHarvest': 'Zniszczone zbiory',
  'Damages': 'Odszkodowanie',
  'Dashboard': 'Panel',
  'Date': 'Data',
  'Date-s': 'Daty',
  'DateRange': 'Zakres dat',
  'DeadPlantsPct': 'Rośliny martwe (%)',
  'DeclaredHazard': 'Zadeklarowane zagrożenie',
  'DeclaredLossCauses': 'Zadeklarowane przyczyny szkody',
  'Decline': 'Spadek',
  'Deductible': 'Odliczenie',
  'Deductible-s': 'Odliczenie(-a)',
  'Delete': 'Usuń',
  'DeleteForEveryone': 'Usuń dla wszystkich',
  'DeleteOverrideSampleMsg': 'Czy na pewno chcesz usunąć oszacowanie zastąpienia? Jeżeli tak, powrócimy do próbek, aby uzyskać zagregowaną szacunkową wartość na poziomie uprawy.',
  'DeleteUser': 'Usuń użytkownika',
  'DeleteUsers': 'Usuń użytkowników',
  'Delimiter': 'Ogranicznik',
  'Descending': 'malejąco',
  'DetachedSample-s': 'Odłączone próbki',
  'Details': 'Detale',
  'DetectDensity': 'Wykryj gęstość',
  'DisablePoiMode': 'Pokaż wszystkie gospodarstwa',
  'Done': 'Gotowe',
  'Download': 'Pobierz',
  'DownloadGeojson': 'Pobierz plik GeoJSON',
  'DownloadXLSX': 'Pobierz plik Excel',
  'Downloading': 'Pobieranie',
  'DownloadingInitialData': 'Pobieranie danych początkowych',
  'Draw': 'Rysuj',
  'DuplicateEntryWasRemoved': 'Następująca pozycja już istnieje i nie zostanie zaimportowana:',
  'ESP': 'Hiszpania',
  'EarDensity': 'Zagęszczenie kłosów',
  'Edit': 'Edytuj',
  'EditClaim': 'Edytuj roszczenie',
  'EditFarm': 'Edytuj gospodarstwo',
  'EditField': 'Edytuj działkę',
  'EditFieldShape': 'Edytuj granice działki',
  'EditHarvest': 'Edytuj zbiór',
  'EditPolicy': 'Edytuj polisę',
  'EditSample': 'Edytuj próbkę',
  'Editors': 'Edytorzy',
  'Email': 'E-mail',
  'EmailMissing': 'Wpisz swój adres e-mail',
  'Emergence': 'Kiełkowania',
  'EmergenceDate': 'Data kiełkowania',
  'EnableNow': 'Włącz teraz',
  'EnablePoiMode': 'Pokaż tylko interesujące gospodarstwa',
  'EnablePreciseLocation': 'Włącz Dokładną lokalizację',
  'EndDate': 'Data końcowa',
  'Entity': 'Jednostka',
  'ErrMissingFields': 'Wypełnij wszystkie podkreślone powyżej pola.',
  'Error': 'Błąd',
  'EstimatedLossAbbr': 'Szac. strata',
  'EstimatedYield': 'Szacowane plony',
  'EstimatedYieldAbbr': 'Szac. uzysk',
  'EstimatedYieldAreaWeighted': 'Szacowany plon (ważony powierzchniowo)',
  'EstimatedYieldArithmeticMean': 'Szacowany plon (średnia arytmetyczna)',
  'EstimatedYieldLoss': 'Szacowana strata w zbiorach',
  'Estimation': 'Oszacowanie',
  'EventDate': 'Data zdarzenia',
  'EventValidation': 'Weryfikacja zdarzenia',
  'Existing': 'Istniejące',
  'ExistingClaim': 'Istniejące roszczenie',
  'ExistingClaimMsg': 'Istnieje już wniosek dotyczący tego gospodarstwa. Czy chcesz kontynuować istniejące roszczenie?',
  'ExistingFarm': 'To gospodarstwo już istnieje.',
  'ExoBinUnsupported': 'Ta funkcja nie jest obecnie obsługiwana w tej wersji aplikacji. Proszę zaktualizować aplikację do najnowszej wersji.',
  'ExoCamera': 'Kamera Exo',
  'ExoDetectionError': 'Nie można wykryć uszu. Spróbuj ponownie, dostosowując położenie lub robiąc nowe zdjęcie.',
  'ExoModelNotDownloaded': 'Nie pobrano modelu Exo. Aby pobrać model do użytku offline, połącz się z Internetem.',
  'ExoNotEnabledForUser': 'Ta funkcja nie jest włączona dla Twojej firmy lub grupy użytkowników. Skontaktuj się ze swoim administratorem.',
  'ExoTutorialPanDescription': 'Przesuwaj obraz jednym palcem.',
  'ExoTutorialPinchDescription': 'Ściśnij obraz, aby powiększyć lub pomniejszyć.',
  'ExoTutorialSearchDescription': 'Dopasuj strefę wykrywania do zamierzonej części obrazu, aż zmieni kolor na zielony.',
  'Expert': 'Ekspert',
  'Expert-s': 'Ekspert/ci',
  'ExpertName': 'Imię eksperta',
  'ExpertRanking': 'Ranking techników',
  'ExpertReport': 'Raport specjalisty',
  'ExternalClaimId': 'Zewnętrzny identyfikator roszczenia',
  'ExternalFieldId': 'Identyfikator pola zewnętrznego',
  'ExtremelyHighTemperature': 'Ekstremalna fala upałów (Tmax>38°C)',
  'ExtremelyLowTemperature': 'Ekstremalne ryzyko mrozu (-15°C>Tmin)',
  'FRA': 'Francja',
  'FailedToSyncChanges': 'Nie udało się zsynchronizować zmian',
  'Farm': 'Gospodarstwo',
  'FarmAddress': 'Adres gospodarstwa',
  'FarmAndHarvest': 'Informacje o farmie i zbiorach',
  'FarmDeletedPleaseStartOver': 'Gospodarstwo zostało usunięte! Proszę zacząć od nowa.',
  'FarmFormComment': 'Dodatkowe informacje operacyjne',
  'FarmHarvestLevel': 'Poziom żniwa',
  'FarmHarvestLevelObservation': 'Obserwacje na poziomie zbiorów',
  'FarmHarvests': 'Zbiory dla gospodarstwa',
  'FarmInfo': 'Informacje o gospodarstwie',
  'FarmLocationPrompt': 'Naciśnij i przytrzymaj na mapie, aby ustawić lokalizację gospodarstwa lub naciśnij strzałkę po lewej stronie, aby użyć swojej lokalizacji',
  'FarmName': 'Nazwa gospodarstwa',
  'FarmReference': 'Odniesienie do gospodarstwa',
  'Farmer': 'Rolnik',
  'FarmerName': 'Imię rolnika',
  'FarmerOrRepresentative': 'Rolnik lub przedstawiciel',
  'FarmerRepresentative': 'Przedstawiciel rolników',
  'FarmersEstimatedYieldLoss': 'Szacunkowa strata plonów rolnika',
  'Farms': 'Gospodarstwa',
  'FeasibleYield': 'Szacowany uzysk',
  'FeasibleYieldEmpty': 'Możliwa wydajność jest pusta.',
  'Feedback': 'Opinia',
  'FetchReportOfflineInfo': 'Wygląda na to, że jesteś offline, dlatego nie można pobrać raportu.',
  'Field': 'Działka',
  'Field-s': 'Pole(a)',
  'FieldArea': 'Powierzchnia pola',
  'FieldAreaAndReference': 'Powierzchnia pola i odnośniki',
  'FieldCultivatedArea': 'Powierzchnia uprawna (pole)',
  'FieldFormComment': 'Dodatkowe informacje o fabule',
  'FieldHarvests': 'Zbiory polowe',
  'FieldInfo': 'Informacje o działce',
  'FieldLevel': 'Poziom pola',
  'FieldLocationPrompt': 'Naciśnij i przytrzymaj na mapie, aby ustawić lokalizację działki lub naciśnij strzałkę po lewej stronie, aby użyć swojej lokalizacji',
  'FieldManagement': 'Zarządzanie pola',
  'FieldMissingCurPolicyHarvest': 'Ta działka nie ma aktualnej polisy i przypisanego zbioru. Kliknij tutaj, aby je przypisać.',
  'FieldReference': 'Odniesienie do działki',
  'FieldScoring': 'Punktacja terenowa',
  'FieldShapeAndFarm': 'Kontur pola i gospodarstwo',
  'Fields': 'Pola',
  'FileActions': 'Akcje plików',
  'FilterList': 'Lista filtrów',
  'Filters': 'Filtry',
  'FirstAndLastName': 'Imię i nazwisko',
  'FirstName': 'Imię',
  'FloodZone': 'Strefa powodziowa',
  'ForgotPassword': 'Zmień hasło',
  'FormSubmitted': 'Zapisano!',
  'FrontPageReportDisclaimer': 'Z zastrzeżeniem ważności gwarancji',
  'FungicideApplied': 'Zastosowano środek grzybobójczy',
  'GPSModuleUnavailable': 'Moduł GPS niedostępny',
  'Generate': 'Generuj',
  'GenerateReport': 'Generuj raport',
  'GeneratingReportPleaseWait': 'Generowanie raportu, proszę czekać...',
  'GeographicalArea': 'Obszar geograficzny',
  'GetDirectionsField': 'Wskazówki dojazdu do działki',
  'GrainMoisture': 'Wilgotność ziarna',
  'GrainMoistureReduction': 'Redukcja z powodu wilgotności ziarna',
  'GrantsTo': 'Dotacje dla',
  'GrapeVariety': 'Odmiana winorośli',
  'GrossSampleProduction': 'Produkcja próbki brutto',
  'GuaranteedLosses': 'Gwarantowane straty',
  'HUN': 'Węgry',
  'HailInfo': 'Ta warstwa wyświetla ryzyko wystąpienia gradobicia.',
  'Harvest': 'Żniwa',
  'HarvestArea': 'Obszar uprawy',
  'HarvestAreaMustBePositiveAndLessThanFieldArea': 'Powierzchnia zbiorów musi być dodatnia i nie większa niż powierzchnia pola!',
  'HarvestFormComment': 'Dodatkowe informacje o zbiorach',
  'HarvestInfo': 'Informacja o zbiorach',
  'HarvestLoss': 'Utrata w zbiorach',
  'HarvestModificationDisabledMsg': 'Zbiór ten nie podlega modyfikacji, gdyż został utworzony przez ubezpieczyciela.',
  'HarvestPlotId': 'Identyfikator wątku pobocznego',
  'HarvestPractice': 'Rodzaj zbioru',
  'HarvestReference': 'Odniesienie do zbiorów',
  'HarvestYear': 'Rok zbioru',
  'Harvester': 'Kombajn',
  'HarvesterDistanceHarvested': 'Zebrana odległość',
  'HarvesterPlatformWidth': 'Szerokość zbioru platformy',
  'Harvests': 'Zbiory',
  'HarvestsWillNotBeUpdated': 'Zbiory z tego pola nie będą aktualizowane.',
  'HelloReportIssue': 'Witam, chciałbym/chciałabym zgłosić następujący problem z aplikacją GreenTriangle:',
  'HerbicideApplied': 'Zastosowano herbicyd',
  'HereAddAsNew': 'Kliknij tutaj, aby zamiast tego dodać nowe gospodarstwo.',
  'HideDataFromMap': 'Ukryj dane z mapy',
  'HighHailRisk': 'Wysokie ryzyko uszkodzenia przez grad',
  'HighPWind': 'Sztorm (28.5-32.5 m/s)',
  'HighPrecipitation': 'Wysokie opady (30-50 mm/tydz.)',
  'HighSoilMoisture': 'Nieco większa wilgotność niż zazwyczaj',
  'HighTemperature': 'Lekka fala upałów (25°C<Tmax<29°C)',
  'HighVegetation': 'Nieco lepsza kondycja roślinności niż zazwyczaj (+25%)',
  'Higher': 'Wyższe',
  'HigherSoilMoisture': 'Większa wilgotność niż zazwyczaj',
  'HigherTemperature': 'Umiarkowana fala upałów (29°C<Tmax<34°C)',
  'HigherVegetation': 'Lepsza kondycja roślinności niż zazwyczaj (+40%)',
  'Highest': 'Najwyższe',
  'HighestSoilMoisture': 'O wiele większa wilgotność niż zazwyczaj',
  'HighestTemperature': 'Ciężka fala upałów (Tmax>34°C)',
  'HighestVegetation': 'Znacznie lepsza kondycja roślinności niż zazwyczaj (+55% lub więcej)',
  'HistoricalDeclaredYields': 'Historyczne deklarowane plony',
  'HistoricalObservedYields': 'Historyczne zaobserwowane stopy zwrotu',
  'HistoricalRealizedYields': 'Historyczne zrealizowane plony',
  'Homogeneity': 'Jednorodność',
  'HomogeneityInfo': 'Wynik jednorodności mierzy, jak jednorodne było pole w przeszłości pod względem rozwoju upraw',
  'ID': 'ID',
  'ITA': 'Włochy',
  'ImportData': 'Importuj dane',
  'Impurities': 'Zanieczyszczenia',
  'Info': 'Informacje',
  'InheritedFrom': 'Odziedziczony po',
  'Initials': 'Inicjały',
  'InsecticideApplied': 'Zastosowano środek owadobójczy',
  'Insee': 'INSEE',
  'Install': 'zainstalować',
  'InsuredArea': 'Obszar ubezpieczony',
  'InsuredCropRequired': 'Aby kontynuować, wybierz co najmniej jedną ubezpieczoną uprawę.',
  'InsuredCrops': 'Ubezpieczone uprawy',
  'InsuredPrice': 'Cena ubezpieczona',
  'InsuredProduction': 'Ubezpieczona produkcja',
  'InsuredYield': 'Ubezpieczony uzysk',
  'InterfieldInfo': '„Porównanie pól” pokazuje całkowity stan roślinności i umożliwia bezpośrednie porównanie ogólnego stanu roślinności dla różnych pól.',
  'InteryieldInfo': 'Przewidywanie plonów wyświetla przewidywany plon na podstawie danych teledetekcyjnych i klimatycznych dostępnych do podanej daty.',
  'IntrafieldInfo': 'Zmienność wewnątrz pola wyświetla znormalizowany stan roślinności dla monitorowanego obszaru. Wykres jest podzielony na 5 stref o równej powierzchni odpowiadającej 20% (kwintyl) wartości indeksu, od najniższej do najwyższej.',
  'InvalidShapeTraced': 'Twój kształt nie może mieć przecinających się linii. Proszę narysować prosty kształt bez skrzyżowań.',
  'Irrigated': 'Nawadnianie',
  'IrrigatedQuestionMark': 'Nawadniane?',
  'KernelsPerEar': 'Liczba ziaren w kłosie',
  'KernerlsPerUnit': 'Ziarna na jednostkę',
  'LTU': 'Litwa',
  'LastChanged': 'Ostatnia zmiana',
  'LastName': 'Nazwisko',
  'Legend': 'Legenda',
  'LessDetails': 'Mniej szczegółów',
  'LinkCopiedToClipboard': 'Link został skopiowany do schowka',
  'LinkToThisLocation': 'Link do tej lokalizacji',
  'List': 'Lista',
  'LiveSampleFeed': 'Próbki w czasie rzeczywistym',
  'Loading': 'Ładowanie',
  'Locale': 'Język',
  'Location': 'Lokalizacja',
  'LocationAndFarm': 'Lokalizacja i Gospodarstwo',
  'LocationMocked': 'Wygląda na to, że ktoś wyśmiewa Twoją lokalizację. Aby kontynuować, użyj swojej prawdziwej lokalizacji.',
  'LocationNotProvided': 'Aby korzystać z tej funkcji, musisz włączyć lokalizację.',
  'Login': 'Zaloguj się',
  'Logout': 'Wyloguj',
  'LossAdjuster': 'Technik',
  'LossCause': 'Przyczyna szkody',
  'LossCauseRecognized': 'Przyczyna straty została rozpoznana',
  'LossEstimation': 'Oszacowane straty',
  'LossEventDate': 'Data zdarzenia powodującego stratę',
  'Losses': 'Straty',
  'LowHailRisk': 'Niskie ryzyko uszkodzenia przez grad',
  'LowPrecipitation': 'Niskie opady (10-15 mm/tydz.)',
  'LowSoilMoisture': 'Nieco bardziej sucho niż zazwyczaj',
  'LowTemperature': 'Ryzyko lekkiego mrozu (-1°C<Tmin<2°C)',
  'LowVegetation': 'Nieco gorsza kondycja roślinności niż zazwyczaj (-25%)',
  'LowWind': 'Silny wiatr (17-24.5 m/s)',
  'Lower': 'Niższe',
  'LowerSoilMoisture': 'Bardziej sucho niż zazwyczaj',
  'LowerTemperature': 'Ryzyko umiarkowanego mrozu (-5°C<Tmin<-1°C)',
  'LowerVegetation': 'Gorsza kondycja roślinności niż zazwyczaj (-40%)',
  'Lowest': 'Najniższe',
  'LowestSoilMoisture': 'O wiele bardziej sucho niż zazwyczaj',
  'LowestTemperature': 'Ryzyko silnego mrozu (-5°C>Tmin)',
  'LowestVegetation': 'Znacznie gorsza kondycja roślinności niż zazwyczaj (-55% lub mniej)',
  'MAR': 'Maroko',
  'MDA': 'Mołdawia',
  'MEX': 'Meksyk',
  'ManageUserAccountAndSettings': 'Zarządzaj swoimi kontami i ustawieniami',
  'Manager': 'Menedżer',
  'Map': 'Mapa',
  'MeasureDensity': 'Zmierz gęstość',
  'Median': 'Średnie',
  'Merge': 'Scal',
  'Method': 'metoda',
  'MixedTemperature': 'Strefa o wysokiej amplitudzie temperatury',
  'ModerateHailRisk': 'Umiarkowane ryzyko uszkodzenia przez grad',
  'ModeratePrecipitation': 'Umiarkowane opady (15-30 mm/tydz.)',
  'ModerateWind': 'Wichura (24.5-28.5 m/s)',
  'Months': 'Miesiące',
  'MoreDetails': 'Więcej szczegółów',
  'MoreSpecificHarvestDetected': 'Wykryto bardziej szczegółowe zbiory, które zastąpią istniejące zbiory.',
  'MunicipalMedianVegetation': 'Średnia roślinność gminy',
  'Municipality': 'Miasto',
  'MustAddCustomer': 'Należy dodać co najmniej jednego klienta',
  'MustAddEmail': 'Należy podać prawidłowy adres e-mail',
  'MustSelectACustomer': 'Aby kontynuować, musisz wybrać klienta',
  'MustSelectCropAndYear': 'Musisz wybrać uprawę i rok',
  'NDVIRange': 'Zakres NDVI',
  'NationalYield': 'Plony w skali kraju',
  'NeedCurrentHarvest': 'Zanim przejdziesz dalej, musisz dodać zbiór na ten sezon.',
  'NeedHelpContact': 'Masz problemy z logowaniem? - Skontaktuj się z nami',
  'New': 'Nowy',
  'NewValue': 'Nowa Wartość',
  'NewVersionWarning': 'Dostępna jest nowa wersja aplikacji.',
  'NewVisit': 'Nowa wizyta',
  'Next': 'Wstecz',
  'No': 'Nie',
  'NoClaims': 'Żadnych roszczeń',
  'NoData': 'Nie znaleziono danych.',
  'NoDataFoundForYourFilters': 'Nie mamy danych dla Twoich aktualnych kryteriów filtrowania.',
  'NoFarmEditors': 'Brak redaktorów przypisanych do tej farmy',
  'NoGpsSupport': 'To urządzenie nie jest wyposażone w moduł GPS.',
  'NoHailRisk': 'Brak ryzyka uszkodzenia',
  'NoOfflineFarms': 'Brak farm zapisanych do użytku offline.',
  'NoOngoingVisit': 'Kliknij (+) powyżej, aby rozpocząć nową wizytę',
  'NoPrecipitation': 'Brak opadów (<5 mm/tydz.)',
  'NoSampleDateSelectionWarning': 'Nie wybrano daty pobierania próbek. Wybierz co najmniej jeden, aby uniknąć pustego raportu.',
  'NoTelepacAvailable': 'Telepac niedostępny',
  'NoVariety': 'Brak różnorodności',
  'NoWind': 'Brak lub słaby wiatr (<17 m/s)',
  'NonCompensableLosses': 'Straty niekompensowane',
  'NonIrrigated': 'Nienawadniane',
  'NonOrganic': 'Nie organiczne',
  'NormalSoilMoisture': 'Normalna wilgotność',
  'NormalTemperature': 'Brak ryzyka mrozu/upałów (2°C>Tmin; Tmax<25°C)',
  'NormalVegetation': 'Normalna kondycja roślinności',
  'NotAffected': 'Nie dotyczy',
  'NotAvailable': 'Niedostępne',
  'NumberMatchingFields': 'Liczba pasujących działek',
  'NumberMatchingSamples': 'Liczba pasujących próbek',
  'NumberOfFields': 'Liczba działek',
  'NumberOfGrainsPerRow': 'Liczba ziaren na rząd',
  'NumberOfLossAdjusters': 'Liczba techników',
  'NumberOfPolicies': 'Liczba polis',
  'NumberOfRowsPerHead': 'Liczba rzędów na głowę',
  'NumberOfSamples': 'Liczba próbek',
  'Occurence': 'Występowanie',
  'OfFieldArea': 'powierzchni pola',
  'OfficialHarvestArea': 'Oficjalny obszar zbiorów',
  'Offline': 'Offline',
  'OfflineAreas': 'Obszary offline',
  'OfflineFarms': 'Farmy offline',
  'Ok': 'OK',
  'Ongoing': 'W trakcie',
  'OpenCamera': 'Otwarta kamera',
  'OpenFarm': 'Otwórz gospodarstwo',
  'OpenPolicy': 'Otwórz polisę',
  'OpenVisit': 'Wizyta otwarta',
  'Or': 'Lub',
  'Organic': 'Organiczny',
  'OrganicQuestionMark': 'Organiczny?',
  'OtherCompensableLosses': 'Inne kompensowane straty',
  'OtherFields': 'Inne działki',
  'OverrideCalculation': 'Zastąp łączny zysk',
  'OverrideEstimatedYield': 'Zastąp szacowany plon',
  'Overview': 'Przegląd',
  'PER': 'Peru',
  'POL': 'Polska',
  'PRY': 'Paragwaj',
  'Password': 'Hasło',
  'PasswordMissing': 'Wpisz swoje hasło',
  'PastMonth': 'Poprzedni miesiąc',
  'PastReportsClickToRefresh': 'Wcześniejsze raporty - kliknij aby odświeżyć:',
  'PastWeek': 'Poprzedni tydzień',
  'PastYear': 'Poprzedni rok',
  'Pause': 'Wstrzymaj',
  'PeakVegetationDate': 'Data szczytowej wegetacji',
  'PendingDownload': 'Oczekujące na pobranie',
  'PendingUpload': 'Oczekuje na przesłanie',
  'PerCropSignature': 'Podpis na uprawę',
  'Phone': 'Telefon',
  'Photo': 'Zdjęcie',
  'Photos': 'Zdjęcia',
  'PhotosAndReferences': 'Zdjęcia i Odnośniki',
  'PixelResolution': 'Rozdzielczość pikseli',
  'PlanetTiles': 'Płytki Planet',
  'PlanetTilesBoundsWarning': 'Dla wybranej daty i obszaru nie są dostępne żadne kafelki. Wybierz inną datę lub obszar.',
  'PlantDensity': 'Gęstość roślinności',
  'PlantsCollected': 'Zebrane rośliny',
  'PleaseAddFields': 'Dodaj kilka działek, aby kontynuować',
  'PleaseConnect': 'Nie wykryto połączenia z internetem. Aby się zalogować, upewnij się, że masz połączenie z internetem.',
  'PleaseEnableLocation': 'Udostępnij swoją lokalizację dla aplikacji GreenTriangle.',
  'PleaseEnablePhotoPermissions': 'Aby kontynuować, zezwól na dostęp do kamery w ustawieniach.',
  'PleaseEnterEmail': 'Wpisz e-mail.',
  'PleaseEnterValue': 'Wprowadź wartość.',
  'PleaseReviewReportBeforeSigning': 'Przed podpisaniem zapoznaj się z raportem',
  'PleaseSelectClaim': 'Wybierz roszczenie',
  'PleaseSelectDate': 'Proszę wybrać datę.',
  'PleaseSelectFarm': 'Wybierz gospodarstwo, aby kontynuować',
  'PleaseSelectField': 'Wybierz pole, aby kontynuować',
  'PleaseSelectLocation': 'Wybierz lokalizację, aby kontynuować.',
  'PleaseSelectPolicy': 'Wybierz polisę, aby kontynuować',
  'PleaseUpgradeLatest': 'Twoja wersja GreenTriangle jest nieaktualna. Zaktualizuj do najnowszej wersji',
  'PointOrZone': 'Punkt/Strefa',
  'Policies': 'Polisy',
  'Policy': 'Polisa',
  'PolicyFormComment': 'Dodatkowe informacje o zasadach',
  'PolicyNumber': 'Numer polisy',
  'Polygon': 'Obrys',
  'Portfolio': 'Teczka',
  'PortfolioReport': 'Raport portfela',
  'PotentialDuplicates': 'Następujące dane wyglądają podobnie. Rozważ wybranie jednej z poniższych opcji',
  'Practice': 'Rodzaj',
  'PrecipitationInfo': 'Całkowite tygodniowe opady to nagromadzona woda płynna i zamarznięta, w tym deszcz i śnieg, która spada na powierzchnię Ziemi w ciągu 7 dni. Nie obejmuje mgły, rosy ani opadów atmosferycznych, które odparowują z atmosfery, zanim wylądują na powierzchni Ziemi.',
  'Preview': 'Podgląd',
  'PreviewAndSend': 'Wyświetl podgląd i wyślij',
  'Previous': 'Dalej',
  'PreviousDate-s': 'Poprzednia data(y)',
  'PriceRetainedByExpert': 'Cena ustalona przez rzeczoznawcę',
  'Primary': 'Podstawowa',
  'PrimaryYieldLoss': 'Podstawowa strata w zbiorach',
  'PrivacyPolicy': 'Polityka prywatności',
  'Processing': 'Przetwarzanie',
  'Productivity': 'Wydajność',
  'ProductivityInfo': 'Wynik produktywności szacuje, jak produktywne było dane pole w przeszłości',
  'ROU': 'Rumunia',
  'RUS': 'Rosja',
  'RainstormInfo': 'Maksymalne opady godzinowe w tym tygodniu.',
  'RainstormLevel1': 'Ulewa stopnia 1 (<20 mm/h)',
  'RainstormLevel2': 'Ulewa stopnia 2 (20-30 mm/h)',
  'RainstormLevel3': 'Ulewa stopnia 3 (30-40 mm/h)',
  'RainstormLevel4': 'Ulewa stopnia 4 (40-50 mm/h)',
  'RainstormLevel5': 'Ulewa stopnia 5 (>50 mm/h)',
  'Recipients': 'Odbiorcy',
  'RecognizedHazard': 'Uznane zagrożenie',
  'Reference': 'Odniesienie',
  'Refresh': 'Odświeżać',
  'Region': 'Region',
  'RegionLevel': 'Poziom regionu',
  'RegionLevel_commune': 'Gmina',
  'RegionLevel_country': 'Kraj',
  'RegionLevel_state': 'Państwo',
  'RegionalStatistic': 'Statystyka regionalna',
  'Regions': 'Regiony',
  'RelativeScoring': 'Punktacja względna',
  'RelativeScoringInfo': 'Wyniki względne odzwierciedlają różnicę wyniku pola ze średnim wynikiem pól regionu (przy użyciu około 100 przykładowych pól), podczas gdy wynik %-ile umieszcza wynik pola w obrębie pól regionu (5 to górny percentyl, 1 dolny percentyl, 3 mediana)',
  'Remove': 'Usunąć',
  'RemoveCrop': 'Usuń uprawę',
  'RemoveFarm': 'Usuń gospodarstwo',
  'RemoveField': 'Usuń działkę',
  'RemoveHarvest': 'Usuń sbiór',
  'RemoveLoss': 'Usuń stratę',
  'RemovedGroups': 'Usunięte grupy',
  'RenewHarvests': 'Odnów żniwa',
  'ReportDate': 'Data raportu',
  'ReportDoesntExist': 'Raport nie istnieje',
  'ReportGenerationPageComment': 'Ogólny komentarz do raportu',
  'ReportNoFields': 'To gospodarstwo nie ma żadnych działek, dla których można utworzyć raport.',
  'Reset': 'Zresetuj',
  'ResetLink': 'Wyślij link do zmiany',
  'Restore': 'Przywróć',
  'Resume': 'Wznów',
  'Risks': 'Zagrożenia',
  'RowDistanceCollected': 'Zebrana odległość rzędu',
  'RowsAndWidth': 'Rzędy i szerokość',
  'SRB': 'Serbia',
  'Sample': 'Próbka',
  'Sample-s': 'Próbki',
  'SampleCropTitle': 'Próbki według rodzaju upraw',
  'SampleDate': 'Data próbki',
  'SampleFormComment': 'Komentarz/obserwacja',
  'SampleFormMissingImagesWarning': 'Nie zrobiłeś zdjęcia dla tej próbki. Dodaj jeden, aby kontynuować.',
  'SampleLocation': 'Lokalizacja próbki',
  'SampleLocationInaccurate': 'Niedokładna lokalizacja — włącz opcję Dokładna lokalizacja, jeśli jeszcze tego nie zrobiono. Następnie kliknij ponownie „Użyj mojej lokalizacji”.',
  'SampleLocationOutsideField': 'Lokalizacja próbki nie znajduje się w wybranym polu.',
  'SampleLocationPrompt': 'Wybierz docelową działkę dla próbki, a następnie naciśnij i przytrzymaj na mapie, aby ustawić lokalizację próbki lub naciśnij strzałkę po lewej stronie, aby użyć swojej lokalizacji',
  'SampleType': 'Typ próbki',
  'SampledArea': 'Próbkowany obszar',
  'SampledBy': 'Próbowano przez',
  'Samples': 'Próbki',
  'SamplingDate-s': 'Data(-ty) pobierania próbek',
  'Save': 'Zapisz',
  'SaveOffline': 'Zapisz offline',
  'Saving': 'Zapisywanie',
  'Score': 'Wynik',
  'Search': 'Szukaj',
  'SearchAddTipLabel': 'połącz wyszukiwane hasła',
  'SearchExampleDescAddedBy': 'Wpisz adres e-mail, aby wyświetlić próbki dodane przez tego użytkownika, a także działki i gospodarstwa dla tych próbek',
  'SearchExampleDescAddress': 'Wpisz adres, aby ponownie wyśrodkować mapę dla tej lokalizacji.',
  'SearchExampleDescCropCondition': 'Wpisz stan upraw, aby wyróżnić próbki, w przypadku których stan upraw został oceniony w określony sposób.',
  'SearchExampleDescFarm': 'Znajdź gospodarstwo oraz jego działki i próbki według nazwy lub adresu',
  'SearchExampleDescHarvest': 'Znajdź wszystkie elementy dotyczące określonego zbioru, wprowadzając rodzaj uprawy, sezon lub rok. Jeśli wprowadzisz rodzaj upraw, wyniki będą ograniczone do tego roku. Podczas drugiego wyszukiwania możesz wyszukać starsze zbiory.',
  'SearchExampleDescPolicy': 'Znajdź wszystkie pozycje związane z numerem polisy',
  'SearchExampleLabelAddedBy': 'znajdź próbki dodane wdług adresu e-mail',
  'SearchExampleLabelAddress': 'miejscowość, adres lub kraj',
  'SearchExampleLabelCropCondition': 'znajdź próbki dla danego stanu uprawy (np. stan „słaby”)',
  'SearchExampleLabelFarm': 'wyszukiwanie według nazwy i adresu gospodarstwa',
  'SearchExampleLabelHarvest': 'szukaj według rodzaju uprawy, np. „pszenica”',
  'SearchExampleLabelPolicy': 'szukaj według numeru polisy',
  'SearchExampleLabelUserGroup': 'znajdź dane w określonym portfolio',
  'SearchForClaimNumber': 'Wyszukaj numer roszczenia',
  'SearchVisit': 'Znajdź nieprzypisaną wizytę',
  'Secondary': 'Dugorzędna',
  'SecondaryYieldLoss': 'Druga strata w zbiorach',
  'SeedWeightPerHead': 'Waga nasion na kłos',
  'Select': 'Wybierz',
  'SelectAll': 'Zaznacz wszystko',
  'SelectAppellation': 'Wybierz apelację',
  'SelectCostType': 'Wybierz typ kosztów',
  'SelectCropCondition': 'Wybierz stan uprawy',
  'SelectCropType': 'Wybierz rodzaj uprawy',
  'SelectCurHarvest': 'Wybierz aktualny zbiór',
  'SelectDate': 'Wybierz datę',
  'SelectFarm': 'Wybierz gospodarstwo',
  'SelectFarmsForOfflineAccess': 'Wybierz farmy, aby uzyskać dostęp w trybie offline',
  'SelectField': 'Wybierz działkę',
  'SelectFieldCurHarvest': 'Wybierz aktualny zbiór dla działki',
  'SelectFieldCurPolicy': 'Wybierz aktualną polisę dla działki',
  'SelectHarvest': 'Wybierz zbiory',
  'SelectHarvestYear': 'Wybierz rok zbioru',
  'SelectLossCause': 'Wybierz przyczynę szkody',
  'SelectOneGroup': 'Proszę wybierać pozycje tylko z jednego portfolio',
  'SelectPac': 'Wybierz prawidłowy plik (.ZIP, .XML, .KML, GeoJSON). Jeśli jesteś pewien, że ten plik jest poprawny, skontaktuj się z nami pod adresem support@green-triangle.com.',
  'SelectPolicy': 'Wybierz polisę',
  'SelectPortfolio': 'Wybierz portfolio',
  'SelectReportType': 'Wybierz typ raportu',
  'SelectSampleType': 'Wybierz Typ próbki',
  'SelectSubplotId': 'Wybierz identyfikator wątku pobocznego',
  'SelectVariety': 'Wybierz opcję Różnorodność',
  'SelectVegetationStage': 'Wybierz etap wzrostu (BBCH)',
  'SelectVisitType': 'Wybierz typ wizyty',
  'SelectWillOverwriteShape': 'Czy na pewno chcesz wybrać ten kontur? Bieżący kontur zostanie nadpisany.',
  'SelectYear': 'Wybierz rok',
  'SelectedAppellation': 'Wybrana apelacja',
  'SelectedCrops': 'Wybrane uprawy',
  'SelectedField': 'Wybrane pole',
  'Send': 'Wyślij',
  'SendFeedback': 'Wyślij opinię',
  'SendTo': 'Wyślij do',
  'Sending': 'Wysyłanie',
  'Sent': 'Wysłano',
  'Settings': 'Ustawienia',
  'SevereHailRisk': 'Bardzo wysokie ryzyko uszkodzenia przez grad',
  'SeverePrecipitation': 'Dotkliwe opady (>75 mm/tydz.)',
  'SevereWind': 'Huragan (>32.5 m/s)',
  'ShapeTracerAddPoint': 'Naciśnij mapę, aby dodać punkt lub naciśnij punkt, aby zmienić jego położenie lub go usunąć.',
  'ShapeTracerEditPoint': 'Przesuń punkt za pomocą niebieskiego inonki lub naciśnij gdziekolwiek indziej, aby wrócić do dodawania punktów.',
  'Share': 'Udostępnij',
  'ShowAll': 'Pokaż wszystko',
  'ShowDataOnMap': 'Pokaż dane na mapie',
  'ShowLastAdded': 'Pokaż ostatnio dodane',
  'ShowOnMap': 'Pokaż na mapie',
  'ShowSamplesAddedIn': 'Pokaż próbki dodane w ostatnim czasie:',
  'SignAndClose': 'Podpisz i zamknij',
  'SignatoryName': 'Nazwisko podpisującego',
  'SignatoryNames': 'Imiona sygnatariuszy',
  'Signature': 'Podpis',
  'Signatures': 'Podpisy',
  'SnowIce': 'Lód Śnieg',
  'SoilMoistureInfo': 'Indeks anomalii wilgotności gleby określa stan wilgotności gleby na głębokości 40 cm w okresie 10 dni w stosunku do średniej długoterminowej (10 lat) z tego samego okresu.',
  'SomethingWentWrongError': 'Coś poszło nie tak.',
  'SowingDate': 'Data siewu',
  'SowingDensity': 'Gęstość siewu',
  'SpaceBetweenPlants': 'Przestrzeń między roślinami',
  'SpaceBetweenRows': 'Przestrzeń między rzędami',
  'SquareArea': 'Powierzchnia Kwadratu',
  'SquareMeterCut': 'Metr kwadratowy cięcia / gęstość roślin',
  'SquareSide': 'Strona Kwadratu',
  'SquareSize': 'Rozmiar kwadratu',
  'Stability': 'Stabilność',
  'StabilityInfo': 'Wynik stabilności mierzy, jak stabilna była wywnioskowana produktywność pola w czasie',
  'StartDate': 'Data rozpoczęcia',
  'StartingYield': 'Realny plon',
  'Status': 'Status',
  'SubjectToValidationOfReseedingByExpert': 'Pod warunkiem zatwierdzenia ponownego siewu przez eksperta',
  'Subplots': 'Wątki poboczne',
  'Summary': 'Podsumowanie',
  'SupportedSurface': 'Obsługiwana powierzchnia',
  'SureAddSampleWithoutField': 'Czy na pewno chcesz dodać próbkę bez pola?',
  'SureCancel': 'Czy na pewno chcesz anulować przed zapisaniem wszystkich danych?',
  'SureCloseVisit': 'Czy na pewno chcesz zakończyć wizytę? Protokół nie został podpisany.',
  'SureCloseVisitPartial': 'Czy na pewno chcesz zakończyć wizytę? Protokół został podpisany tylko częściowo.',
  'SureDeleteFarm': 'Czy na pewno chcesz usunąć to gospodarstwo?',
  'SureDeleteField': 'Czy na pewno chcesz usunąć tą działkę?',
  'SureDeleteHarvest': 'Czy na pewno chcesz usunąć ten zbiór?',
  'SureDeleteSample': 'Czy na pewno chcesz usunąć tę próbkę?',
  'SureDeleteVisit': 'Czy na pewno chcesz usunąć tę wizytę?',
  'SureReset': 'Czy na pewno chcesz zresetować? Utracisz wszystkie zmiany dokonane w trybie offline.',
  'SureSelectObject': 'Czy na pewno chcesz zamiast tego wybrać następujące opcje:',
  'SureSignOut': 'Czy na pewno chcesz się wylogować? Po wylogowaniu utracisz wszystkie zmiany w trybie offline.',
  'SurfacePAC': 'Powierzchniowy PAC',
  'SurfaceTemperatureInfo': 'Ta warstwa wyświetla tygodniowe minimalne i maksymalne temperatury gleby w celu wykrycia ryzyka mrozu i fal upałów.',
  'Syncing': 'Synchronizowanie',
  'TableNoRowsFound': 'Nie znaleziono żadnych wierszy',
  'TableOf': 'z',
  'TablePage': 'Strona',
  'TableRows': 'wiersze',
  'TelepacAvailableFor': 'Telepac przesłany dla',
  'TelepacNr': 'Numer Telepaca',
  'TelepacReport': 'Import PAK',
  'TemperatureInfo': 'Ta warstwa wyświetla tygodniowe minimalne i maksymalne temperatury powietrza na wysokości 2m w celu wykrycia ryzyka mrozu i fal upałów.',
  'Tertiary': 'Trzeciorzędna',
  'TertiaryYieldLoss': 'Trzecia strata w zbiorach',
  'ThousandKernelWeightGrams': 'Masa 1000 ziaren (g)',
  'TopNCropsByHarvestArea': 'Najlepsze uprawy według obszaru zbiorów',
  'Total': 'Całkowity',
  'TotalArea': 'Całkowita powierzchnia',
  'TotalCultivatedArea': 'Powierzchnia uprawna (ogółem)',
  'TotalEarsDetected': 'Wykryto łącznie uszy',
  'TotalHarvestArea': 'Całkowita powierzchnia zbiorów',
  'TotalLoss': 'Całkowita utrata',
  'TotalYieldPctLoss': 'Całkowita strata w zbiorach (%)',
  'UKR': 'Ukraina',
  'URY': 'Urugwaj',
  'USA': 'USA',
  'UnableToUpload': 'Błąd przesyłu danych',
  'UnassignMyself': 'Usuń przypisanie siebie',
  'Uncategorized': 'Bez kategorii',
  'Undo': 'Cofnij',
  'UnguaranteedLosses': 'Niegwarantowane straty',
  'Unit': 'Jednostka',
  'UnitsPerPlant': 'Jednostka na roślinę',
  'UnknownErrorOccurred': 'Wystąpił nieznany błąd',
  'UpcomingInspection': 'Zbliżająca się kontrola',
  'Update': 'Aktualizacja',
  'UpdateMemberships': 'Zaktualizuj członkostwo',
  'UploadPACFile': 'Prześlij plik PAC',
  'Usage': 'Użytkowanie',
  'UseMyLocation': 'Użyj mojej lokalizacji',
  'User': 'Użytkownik',
  'UserAdminNoAccess': 'Prawa wybranych klientów użytkowników i uprawnienia administratora użytkownika nie pokrywają się.',
  'UserAdminNoCommon': 'Wybrani użytkownicy nie mają wspólnej firmy.',
  'UserAdministration': 'Administracja użytkownika',
  'UserGroup': 'Portfolio',
  'Validation': 'Walidacja',
  'VegetationInfo': 'Anomalia kondycji roślinności stanowi alternatywną miarę względnego stanu roślinności w porównaniu z poprzednimi latami (od 2001). Wskaźnik ten można wykorzystać do monitorowania obszarów, na których roślinność może być zagrożona, jako wskaźnik zastępczy do wykrywania potencjalnej suszy.',
  'VegetationStage': 'Etap wzrostu',
  'VeryHighPrecipitation': 'Bardzo wysokie opady (50-75 mm/tydz.)',
  'VeryLowPrecipitation': 'Bardzo niskie opady (5-10 mm/tydz.)',
  'ViewThisInstead': 'Czy chcesz wyjść z tego formularza i zamiast tego otworzyć następujące:',
  'VinificationRatio': 'Współczynnik winifikacji (kg/hL)',
  'Visit': 'Odwiedzać',
  'VisitAssign': 'Przypisz wizytę',
  'VisitCandidateStatus_accepted': 'Przyjęty',
  'VisitCandidateStatus_assigned': 'Przydzielony',
  'VisitCandidateStatus_rejected': 'Odrzucony',
  'VisitList': 'Lista wizyt',
  'VisitMode': 'Tryb wizyty',
  'VisitReport': 'Raport z wizyty',
  'VisitReportEmailInfoMsg': 'Spowoduje to wysłanie podpisaneg raportu z wizyty na wybrane adresy e-mail.',
  'VisitType': 'Typ wizyty',
  'VisitValidation': 'Odwiedź Walidację',
  'VisitYear': 'Odwiedź rok',
  'Visited': 'Odwiedziłem',
  'Visits': 'Odwiedziny',
  'Volatility': 'Zmienność',
  'WebApp': 'Aplikacja internetowa',
  'WeightPerPlant': 'Średnia masa na roślinę',
  'WeightPerUnit': 'Waga na jednostkę',
  'WeightedAverage': 'Średnia ważona',
  'WindInfo': 'Maksymalna tygodniowa warstwa podmuchów wiatru przedstawia maksymalny podmuch wiatru zarejestrowany w ciągu ostatnich 7 dni na wysokości dziesięciu metrów nad powierzchnią Ziemi.',
  'Withdrawal': 'Wycofanie',
  'WouldLikeToRestore': 'Znaleźliśmy dane dla tego formularza, które nie zostały zapisane z powodu awarii lub aktualizacji. Czy chcesz odzyskać te dane?',
  'Year': 'Rok',
  'YearEg': 'Rok (np. 2019)',
  'Yes': 'Tak',
  'YieldAndLoss': 'Uzysk i Strata',
  'YieldCalcUnsupportedCrop': 'Kalkulator nie obsługuje dla tego rodzaju upraw. Obecnie możliwe jest tylko przypisanie gęstości roślin.',
  'YieldEstimationMethod': 'Metoda szacowania plonów',
  'YieldLoss': 'Strata w zbiorach',
  'YieldLossInputHidden': 'Wejście utraty wydajności jest wyłączone, ponieważ przyczyna utraty nie została rozpoznana.',
  'YieldRatio': 'Współczynnik wydajności',
  'YieldTooLarge': 'Wartość uzysku jest za duża',
  'YouMayTryAgain': 'Możesz spróbować ponownie',
  'ZAF': 'Republika Południowej Afryki',
  'ZoomInViewLayer': 'Przybliż, aby zobaczyć tę warstwę.',
  'ZoomOutViewLayer': 'Oddal, aby zobaczyć tę warstwę.',
  'additional-costs': 'Dodatkowe koszty (zł)',
  'agroforestry': 'Agroleśnictwo',
  'alfalfa': 'Lucerna siewna',
  'allSynced': 'Wszystko zostało zsynchronizowane z bazą danych',
  'almond': 'Migdały',
  'ananas': 'Ananas',
  'angelica': 'Dzięgiel',
  'anise': 'Anyż',
  'apples': 'Jabłka',
  'apples-cider': 'Jabłka (cydr)',
  'apples-pre-prod': 'Jabłka (5 lat lub mniej)',
  'apricots': 'Morela',
  'apricots-pre-prod': 'Morele (5 lat lub mniej)',
  'aromatic-plants': 'Zioła',
  'artichoke': 'Karczoch',
  'asparagus': 'Szparagi',
  'avocado': 'Awokado',
  'bananas': 'Banany',
  'barley': 'Jęczmień',
  'barley-6-row': 'Jęczmień 6-rzędowy',
  'barley-malting': 'Jęczmień słodowy',
  'barley-seeds': 'Jęczmień (nasiona)',
  'base': 'Mapa podstawowa',
  'basic': 'Podstawowy',
  'basil': 'Bazylia',
  'bbch-00-grapes': 'A. Pączek zimowy',
  'bbch-05-grapes': 'B. Pączek wełnisty',
  'bbch-09-grapes': 'C. Pączek (zielona końcówka)',
  'bbch-11-grapes': 'D. Pojawienie się liści',
  'bbch-13-grapes': 'E. Liście oddzielone',
  'bbch-53-grapes': 'F. Widoczne kwiatostany',
  'bbch-55-grapes': 'G. Kwiatostany oddzielone',
  'bbch-57-grapes': 'H. Kwiaty rozdzielone',
  'bbch-64-grapes': 'I. Bloom',
  'bbch-71-grapes': 'J. Zestaw owoców',
  'bbch-75-grapes': 'K. Jagody wielkości grochu',
  'bbch-77-grapes': 'L. Dotykanie jagód',
  'bbch-81-grapes': 'M. Veraison',
  'bbch-89-grapes': 'N. Dojrzałość',
  'bbch-93-grapes': 'O. Dojrzewanie pędów/opadanie liści',
  'beans': 'Fasola',
  'beans-dry': 'Sucha fasola',
  'beans-green': 'Fasola szparagowa',
  'beans-red': 'Fasola czerwona',
  'beans-seeds': 'Fasola (nasiona)',
  'beans-white': 'Fasola biała',
  'beetroot': 'Burak',
  'benchmark-yield': 'Wydajność bazowa',
  'black-medick': 'Czarny medyk',
  'blackberry': 'Jeżyna',
  'blackcurrant': 'Czarna porzeczka',
  'blueberry': 'Borówka amerykańska',
  'broccoli': 'Brokuł',
  'bromes': 'Bromy',
  'buckwheat': 'Gryka',
  'burdock': 'Łopian',
  'cabbages': 'Kapusta',
  'cabbages-feed': 'Kapusta (pasza)',
  'cabbages-red': 'czerwona kapusta',
  'camelina': 'Camelina',
  'caraway': 'Kminek',
  'cardoon': 'Karczoch hiszpański',
  'carob': 'Chleb świętojański',
  'carrots': 'Marchew',
  'carrots-feed': 'Marchew (pasza)',
  'carrots-young': 'Marchew',
  'cassava': 'Maniok',
  'castor-bean': 'Rącznik pospolity',
  'castration-costs': 'Koszty kastracji ($)',
  'cauliflowers': 'Kalafiory',
  'cedrate': 'Cedrat',
  'celeri-leaves': 'Seler (liście)',
  'celeri-root': 'Seler (korzeń)',
  'celery': 'Seler',
  'cereals': 'Zboża',
  'chamomile': 'Rumianek',
  'chard': 'Boćwina',
  'cherries': 'Wiśnia',
  'chervil': 'Trybula',
  'chestnut': 'kasztan',
  'chia': 'Chia',
  'chickpeas': 'Ciecierzyca',
  'chicory': 'Cykoria',
  'chilli': 'Chilli',
  'chives': 'Szczypiorek',
  'citrus': 'Owoce cytrusowe',
  'claim-all': 'Ostateczne zamknięcie misji',
  'claim-delivery': 'Ostateczne zamknięcie wyników zbiorów',
  'claim-some': 'Ostateczne zamknięcie kultury',
  'claims-visit': 'Ostateczna wizyta roszczenia',
  'claims-visit-file': 'oszacować',
  'clementines': 'Klemendyny',
  'clover': 'Koniczyna',
  'cocoa': 'Kakao',
  'coffea': 'Kawa',
  'cold': 'Zimno',
  'compensation-costs': 'Koszty odszkodowania (zł)',
  'connected': 'Aplikacja jest połączona z internetem.',
  'coriander': 'Kolendra',
  'corn': 'Kukurydza',
  'corn-grain': 'Kukurydza (ziarno)',
  'corn-popcorn': 'Kukurydza (popcorn)',
  'corn-seeds': 'Kukurydza (nasiona)',
  'corn-seeds-fertile': 'Kukurydza (nasiona, żyzna)',
  'corn-seeds-sterile': 'Kukurydza (nasiona, sterylna)',
  'corn-silage': 'Kukurydza (kiszonka)',
  'corn-sweet': 'Kukurydza cukrowa',
  'corn-sweet-seeds-fertile': 'Kukurydza cukrowa (nasiona, żyzna)',
  'corn-sweet-seeds-sterile': 'Kukurydza cukrowa (nasiona, sterylna)',
  'corn-waxy': 'Kukurydza (woskowa)',
  'cornsalad': 'Roszpunka',
  'cotton': 'Bawełna',
  'cover-crop': 'Roślina okrywowa',
  'cranberry': 'Żurawina',
  'cress': 'Rzeżucha',
  'crop-mon': 'Monitorowanie upraw',
  'crop_condition': 'Stan uprawy',
  'crosne': 'Crosne',
  'cucumbers': 'Ogórki',
  'cumin': 'Kminek',
  'cynara': 'Cynara',
  'dactylis': 'Daktylida',
  'damson': 'Damaszka',
  'decree': 'Oficjalny dekret',
  'development': '4. Rozwój części roślin przeznaczonych do zbioru',
  'dill': 'Koperek',
  'disease': 'Choroba',
  'downpour': 'Ulewa',
  'drought': 'Susza',
  'eggplant': 'Bakłażan',
  'elder': 'Starszy',
  'endives': 'Edywia',
  'excess-water': 'Nadmiar wody',
  'excessive-air-humidity': 'Nadmierna wilgotność (powietrze)',
  'expected-loss': 'Oczekiwana szkoda',
  'fallow-land-perennial': 'Ugór (wieloletni)',
  'fallow-land-spring': 'Ugór (wiosna)',
  'false': 'Nie',
  'fennel': 'Koper włoski',
  'fenugreek': 'Kozieradka',
  'festuca': 'Kostrzewa',
  'field-beans': 'Bób',
  'field-beans-seeds': 'Bób (nasiona)',
  'field-crops': 'Rośliny uprawne',
  'field-mustard': 'Kapusta właściwa',
  'figs': 'Figa',
  'fire': 'Pożar',
  'first-crop': 'Pierwsza uprawa',
  'flageolet-bean': 'Fasola jaś',
  'flood': 'Powódź',
  'flood-risk': 'Zagrożenie powodziowe',
  'flowering': '6. Rozkwit',
  'fodder-legumes': 'Rośliny pastewne',
  'forest': 'Las',
  'foxtail-millet': 'Włośnica ber',
  'frost': 'Mróz',
  'fruit-development': '7. Rozwój owoców',
  'full': 'Pełny dostęp',
  'garlic': 'Czosnek',
  'gentiana': 'Goryczka',
  'germination': '0. Kiełkowanie/rozwój pąków',
  'gherkins': 'ógórki kiszone',
  'ginger': 'Ożywić',
  'go': 'Gotowe!',
  'good': 'Dobry',
  'gooseberries': 'Agrest',
  'gourd': 'Gurda',
  'grapefruit': 'Grejpfrut',
  'grapes': 'Winogrona',
  'grapes-food': 'Winogrona (żywność)',
  'grapes-juice': 'Sok winogronowy',
  'grapes-must': 'Winogrona muszą',
  'grapes-nursery': 'Szkółka winorośli',
  'grapes-table': 'Winogrona stołowe',
  'grassland': 'Użytki zielone',
  'grassland-perennial': 'Użytki zielone (wieloletnie)',
  'green-peas': 'Groszek zielony',
  'group-allocator': 'Alokator grupowy',
  'hail': 'Grad',
  'harvest': 'Żniwo',
  'harvest_crop': 'Rodzaj uprawy',
  'harvested': 'Zebrane',
  'harvesting-costs': 'Koszty zbiorów ($)',
  'hazelnuts': 'Orzech laskowy',
  'heat-wave': 'Fala upałów',
  'heatstroke': 'Udar cieplny',
  'heavy-rainfall': 'Intensywne opady',
  'hemp': 'Konopie',
  'hemp-paper': 'Konopia (papier)',
  'hemp-seeds': 'Konopia (nasiona)',
  'high-res-mapbox': 'Wysoka rozdzielczość',
  'historical-yield': 'Historyczny współczynnik wydajności',
  'hop': 'Chmial',
  'hyssop': 'Hizop',
  'inflorescence': '5. Pojawienie się kwiatostanu',
  'interfield': 'Porównanie pól',
  'interfield-a': 'Brak roślinności',
  'interfield-b': 'Roślinność rzadka',
  'interfield-c': 'Roślinność niska',
  'interfield-d': 'Roślinność umiarkowana',
  'interfield-e': 'Roślinność umiarkowana do gęstej',
  'interfield-f': 'Roślinność gęsta',
  'interfield-g': 'Wysoka aktywność chlorofilu',
  'interfield-h': 'Bardzo wysoka aktywność chlorofilu',
  'interfield-i': 'Najwyższa aktywność chlorofilu',
  'interyield': 'Przewidywanie plonów',
  'interyield-a': 'Skrajnie niskie plony',
  'interyield-b': 'Bardzo niskie plony',
  'interyield-c': 'Niskie plony',
  'interyield-d': 'Niskie/umiarkowane plony',
  'interyield-e': 'Umiarkowane plony',
  'interyield-f': 'Umiarkowane/wysokie plony',
  'interyield-g': 'Wysokie plony',
  'interyield-h': 'Bardzo wysokie plony',
  'interyield-i': 'Skrajnie wysokie plony',
  'intrafield': 'Zmienność wewnątrz pola',
  'itemsToUpload': 'Niektre pozycje oczekują na przesłanie do bazy danych.',
  'jerusalem-artichoke': 'Słonecznik bulwiasty',
  'kale': 'Jarmuż',
  'kiwi': 'Kiwi',
  'lack-of-sunlight': 'Brak światła słonecznego',
  'lavandin': 'Lawenda',
  'lavender': 'Lawenda',
  'leaf-development': '1. Rozwój liści',
  'leek': 'Por',
  'lemon-balm': 'Melisa',
  'lemons': 'Cytryna',
  'lentils': 'Soczewica',
  'lentils-berry': 'Soczewica zielona (berry)',
  'lentils-puy': 'Soczewica zielona (puy)',
  'lettuce': 'Sałata',
  'linden': 'Lipa',
  'lineseed-textile-seeds': 'Siemię lniane (tekstylne/nasiona)',
  'linseed': 'Siemię lniane',
  'linseed-oilseed': 'Siemię lniane (nasiona oleiste)',
  'linseed-textile': 'Tkanina lniana',
  'linseed-textile-fiber': 'Siemię lniane (tekstylne/włókniste)',
  'linseed-textile-seeds': 'Siemię lniane (nasiona)',
  'linseed-textile-straw': 'Siemię lniane (tekstylne/słomkowe)',
  'lotus': 'Lotos',
  'lupin': 'Łubin',
  'lupin-sweet': 'Słodki łubin',
  'mandarins': 'Mandarynki',
  'mangos': 'Mango',
  'meadow-grass': 'Trawa łąkowa',
  'medicinal-plants': 'Rośliny lecznicze',
  'melilotus': 'Melilotos',
  'melons': 'Melon',
  'membership_type': 'typ członkostwa',
  'meslin': 'Meslin',
  'millet': 'Proso',
  'mint': 'Mennica',
  'mirabelles': 'Mirabelka',
  'miscanthus': 'Srebrna trawa',
  'mixed-cereal': 'Zboża Mieszane',
  'monitoring-visit': 'Wizyta monitorująca',
  'monitoring-visit-file': 'monitorowanie',
  'monitoring-with-review': 'Dostarczanie/śledzenie (z zarządzaniem, bez dostarczania)',
  'monitoring-with-review-provisioning': 'Dostarczanie/śledzenie (z zarządzaniem, z dostarczaniem)',
  'monitoring-without-review': 'Dostarczanie/monitorowanie (bez zarządzania, bez dostarczania)',
  'monitoring-without-review-provisioning': 'Dostarczanie/monitorowanie (bez zarządzania, z dostarczaniem)',
  'month_0': 'Sty',
  'month_1': 'Lut',
  'month_10': 'Lis',
  'month_11': 'Gru',
  'month_2': 'Mar',
  'month_3': 'Kwi',
  'month_4': 'Maj',
  'month_5': 'Cze',
  'month_6': 'Lip',
  'month_7': 'Sie',
  'month_8': 'Wrz',
  'month_9': 'Paź',
  'most-recent': 'Najnowsze',
  'mustard': 'Gorczyca',
  'nashi-pear': 'Grusza chińska',
  'nectarines': 'Nektarynka',
  'noOfflineAreas': 'Aby móc korzystać z GreenTriangle w trybie offline, pobierz mapy na swoje urządzenie. W tym celu naciśnij Tak.',
  'none': 'Brak',
  'normal': 'Normalny',
  'notConnected': 'Aplikacja nie jest połączona z internetem.',
  'not_available': 'Niedostępne',
  'not_planted': 'Nie obsadzone',
  'nursery': 'Żłobek',
  'oats': 'Owies',
  'oats-seeds': 'Owies (nasiona)',
  'oilseeds': 'Nasiona oleiste',
  'olives': 'Oliwki',
  'onions': 'Cebula',
  'oranges': 'Pomarańcze',
  'orchard': 'Sad (ponad 5 lat)',
  'orchard-pre-prod': 'Sad (5 lat lub mniej)',
  'orchards': 'Sady',
  'oregano': 'Oregano',
  'other-aromatic-medicinal': 'Inne rośliny aromatyczne lub lecznicze',
  'other-berries': 'Inne jagody',
  'other-cereals': 'Inne zboża',
  'other-feed': 'Inne rośliny pastewne',
  'other-fruits': 'Inne owoce',
  'other-industry': 'Inne uprawy przemysłowe',
  'other-legumes': 'Inne rośliny strączkowe',
  'other-loss': 'Inne (strata)',
  'other-oilseed': 'Inne produkty oleiste',
  'other-textile': 'Inne tekstylia',
  'other-vegetables': 'Inne warzywa',
  'parnsip': 'Pasternak',
  'parsley': 'Pietruszka',
  'peach-blood': 'Brzoskwinia krwi',
  'peach-flat': 'Płaska brzoskwinia',
  'peaches': 'Brzoskwinia',
  'peaches-pre-prod': 'Brzoskwinie (5 lat lub mniej)',
  'peanuts': 'Orzech ziemny',
  'pears': 'Gruszki',
  'pears-pre-prod': 'Gruszki (5 lat lub mniej)',
  'peas': 'Groch',
  'peas-feed': 'Groch (pasza)',
  'peas-seeds': 'Groszek (nasiona)',
  'peas-split': 'Groch łuskany',
  'pepper': 'Pieprz',
  'perennial': 'Uprawa wieloletnia',
  'perennial-crops': 'Rośliny wieloletnie',
  'persimmon': 'Persymona',
  'pest': 'Szkodnik',
  'pickle': 'Ogórek konserwowy',
  'pineapples': 'Ananas',
  'pistachio': 'Pistacja',
  'pleaseCheckForm': 'Sprawdź pola formularza i spróbuj jeszcze raz.',
  'plum': 'Śliwki',
  'polyculture': 'Polikultura',
  'poor': 'Słaby',
  'poppy': 'Mak',
  'potatoes': 'Ziemniaki',
  'potatoes-industry': 'Ziemniaki (przemysł)',
  'potatoes-seeds': 'Ziemniaki (nasiona)',
  'potatoes-starch': 'Ziemniaki (skrobia)',
  'potatoes-ware': 'Warte ziemniaki',
  'precipitation': 'Całkowite opady',
  'predicted-yield': 'Przewidywany współczynnik wydajności',
  'preinspection-none': 'Ocena ryzyka',
  'preinspection-visit': 'Wstępna inspekcja',
  'preinspection-visit-file': 'Wstępna inspekcja',
  'production-costs': 'Koszty produkcji ($)',
  'pulses': 'Rośliny strączkowe',
  'pumpkin': 'Dynia',
  'purification-costs': 'Koszty oczyszczania ($)',
  'quinces': 'Pigwa',
  'quinoa': 'Komosa ryżowa',
  'radishes': 'Rzodkiewki',
  'rainstorm': 'Ulewa',
  'rapeseed': 'Rzepak',
  'rapeseed-feed': 'Rzepak (pasza)',
  'rapeseed-seeds': 'Rzepak (nasiona)',
  'raspberry': 'Malina',
  'ray-grass': 'Ray-trawa',
  'redcurrant': 'Porzeczka',
  'remaining-crops': 'Pozostałe uprawy',
  'replanting': 'Przesadzanie',
  'resowing-costs': 'Koszty ponownego siewu (zł)',
  'rhubarb': 'Rabarbar',
  'rice': 'Ryż',
  'ripening': '8. Dojrzewanie owoców i nasion',
  'rocket': 'Rakieta',
  'root-crops': 'Rośliny okopowe',
  'rosemary': 'rozmaryn',
  'rutabaga': 'Brukiew',
  'rye': 'Żyto',
  'rye-seeds': 'Nasiona żyta (hybryda)',
  'safflower': 'Krokosz barwierski',
  'sage': 'Szałwia',
  'sainfoin': 'Sainfoina',
  'salad': 'Sałata',
  'salsify': 'Kozibród',
  'salvia': 'Szałwia',
  'sandstorm': 'Burza piaskowa',
  'satellite': 'Satelita',
  'satureja': 'Satureja',
  'second-crop': 'Druga Uprawa',
  'senescence': '9. Starzenie się, początek spoczynku',
  'shallots': 'Szalotki',
  'side-shoot': '2. Tworzenie pędów bocznych / krzewienie',
  'snow': 'Śnieg',
  'snow-peas': 'Groszek cukrowy',
  'soil-moisture': 'Anomalia wilgotności gleby',
  'sorghum': 'Sorgo (ziarno)',
  'sorghum-feed': 'Sorgo (pasza)',
  'sorghum-seeds': 'Sorgo (nasiona)',
  'sorghum-silage': 'Sorgo (kiszonka)',
  'sorrel': 'Szczaw',
  'soybeans': 'Soja',
  'soybeans-seeds': 'Soja (nasiona)',
  'spelt': 'Orkisz',
  'spinach': 'Szpinak',
  'spring': 'Wiosna',
  'sprouts': 'Brukselka',
  'squash': 'Dynia',
  'squash-max': 'Dynia (Cucurbita maxima)',
  'static-data': 'Dane kraju',
  'stevia': 'Stewia',
  'storm': 'Wichura',
  'strawberry': 'Truskawka',
  'sugar-beet': 'Burak cukrowy',
  'sugar-beet-feed': 'Burak cukrowy (pasza)',
  'sugar-beet-seeds': 'Burak cukrowy (nasiona)',
  'sugar-cane': 'Trzcina cukrowa',
  'sugar-cane-feed': 'Trzcina cukrowa (pasza)',
  'sunburn': 'Oparzenie słoneczne',
  'sunflower': 'Słonecznik',
  'sunflower-oilseed': 'Słonecznik (nasiona)',
  'sunflower-seeds': 'Słonecznik (nasiona)',
  'surface-temperature': 'Ryzyko mrozu i upału (gleba)',
  'switchgrass': 'Przełączgrass',
  'syncDataDb': 'Synchronizowanie informacji z bazą danych.',
  'tarragon': 'Estragon',
  'temperature': 'Ryzyko mrozu i upału (powietrze)',
  'thistle': 'Oset',
  'thyme': 'tymianek',
  'timothy-grass': 'Tymoteusz-trawa',
  'tobacco': 'Tytoń',
  'tobacco-blond': 'Tytoń (blond)',
  'tobacco-brown': 'Tytoń (brązowy)',
  'tobacco-burley': 'Tytoń (burley)',
  'tobacco-virginie': 'Tytoń (Virginia)',
  'tomatoes': 'Pomidory',
  'tomatoes-processing': 'Pomidory (przemysł)',
  'tons': 'T',
  'tornado': 'Tornado',
  'triticale': 'Pszenżyto',
  'triticale-seeds': 'Pszenżyto (nasiona)',
  'true': 'Tak',
  'turmeric': 'Kurkuma',
  'turnip': 'Rzepa',
  'turnip-feed': 'Rzepa (pasza)',
  'unit': 'jednostka',
  'unknown': 'Nieznana',
  'urtica': 'Pokrzywy',
  'user-admin': 'Menedżer użytkownika',
  'valerian': 'Waleriana',
  'validation-visit': 'Wstępna wizyta roszczenia',
  'validation-visit-file': 'występowanie',
  'validation-with-review': 'Występowanie z zarządzaniem',
  'validation-without-review': 'Występowanie bez zarządzania',
  'vanilla': 'Wanilia',
  'vegetables': 'Warzywa',
  'vegetation': 'Anomalia wegetacji',
  'vegetative-growth': '3. Wydłużenie łodygi / wzrost rozety',
  'verbena': 'Werbena',
  'very_good': 'Bardzo dobry',
  'very_poor': 'Bardzo słaby',
  'vetch': 'Wyka',
  'vin-de-table': 'Wino stołowe',
  'walnuts': 'Orzechy włoskie',
  'watermelon': 'Arbuz',
  'weeds': 'Chwasty',
  'wheat': 'Pszenica',
  'wheat-einkorn': 'Pszenica samopsza',
  'wheat-feed': 'Pszenica (pasza)',
  'wheat-hard': 'Pszenica (twarda)',
  'wheat-hard-seeds': 'Pszenica twarda (nasiona)',
  'wheat-seeds': 'Pszenica (nasiona)',
  'wheat-top': 'Pszenica (miękka - ulepszone przemiał)',
  'wild-animals': 'Dzikie zwierzęta',
  'wind': 'Wiatr',
  'winter': 'Zima',
  'yams': 'Ignamy',
  'zucchini': 'Cukinia',
  '-': '-',
  'UnknownLoss': 'Nieznana (Przyczyna szkody)',
  'acres': 'ac',
  'added_by': 'Dodane przez',
  'added_on': 'Dodane',
  'arrobas-bra': '@/ha',
  'bags-per-hectare': 'sc/ha',
  'bales-per-acre': 'bales/ac',
  'bra_agree_with_result': 'Você concorda com o resultado apurado?',
  'bra_average': 'Média (tipo 2)',
  'bra_clay': 'Argiloso (tipo 3)',
  'bra_insured_facilitated': 'O segurado facilitou o processo de amostragem? (Se não, explique nos comentários)',
  'bra_insured_implemented_zarc_window': 'O segurado implantou a cultura dentro da janela de indicação do ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_last_liming_plastering_date': 'Quando foi feita a última calagem e gessagem na área segurada? (descrever a frequência da análise do solo, qual o perfil da camada do solo é trabalhada, tipo de calcário utilizado, faz uso de AP, já utilizou gesso na lavoura, etc)',
  'bra_losses_from_rnc': 'O perito constatou prejuízos por RNC? Explique os percentuais?',
  'bra_manual': 'Manual',
  'bra_mechanized': 'Mecanizada',
  'bra_necessary_to_subdivide': 'Foi necessário a subdivisão dos itens segurados? (Se sim, explique nos comentários)',
  'bra_planned_location': 'A lavoura foi implantada em local previsto? (Se não, explique nos comentários)',
  'bra_recommended_risk_location': 'A cultivar/variedade semeada é recomendada para o local do risco, conforme ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_sampling_type': 'Qual o tipo de amostragem utilizada para estimativa de produtividade da lavoura?',
  'bra_sandy': 'Arenoso (tipo 1)',
  'bra_soil_type': 'Tipo de solo',
  'bra_treatments_effective': 'Os tratos culturais foram efetivos no manejo da cultura?',
  'bra_uniform_management': 'O manejo empregado na lavoura segurada é igual das áreas de recurso próprio? (Se não, explique nos comentários)',
  'bra_visual': 'Visual',
  'brl': 'R$',
  'bushels-per-acre': 'bu/ac',
  'centimeters': 'cm',
  'chf': 'Fr.',
  'corn-grain-white': 'Kukurydza (ziarno) (white)',
  'corn-grain-yellow': 'Kukurydza (ziarno) (yellow)',
  'corn-silage-white': 'Kukurydza (kiszonka) (white)',
  'corn-silage-yellow': 'Kukurydza (kiszonka) (yellow)',
  'crop_id': 'Rodzaj uprawy',
  'custom-a': 'Średnia roślinność gminy',
  'decitons-per-hectare': 'qx/ha',
  'development-cereals': '4. Rozwój części roślin przeznaczonych do zbioru',
  'development-sorghum': '4. Rozwój części roślin przeznaczonych do zbioru',
  'development-sugar-beet': '4. Rozwój części roślin przeznaczonych do zbioru',
  'eur': '€',
  'eur-per-hectoliter': '€/hL',
  'eur-per-ton': '€/t',
  'brl-per-bag': 'R$/sc',
  'farm_id': 'Gospodarstwo',
  'feet': 'ft',
  'flowering-cereals': '6. Rozkwit',
  'flowering-corn': '6. Rozkwit',
  'flowering-fruits': '6. Rozkwit',
  'flowering-grapes': '6. Rozkwit',
  'flowering-legumes': '6. Rozkwit',
  'flowering-rapeseed': '6. Rozkwit',
  'flowering-sorghum': '6. Rozkwit',
  'flowering-soybeans': '6. Rozkwit',
  'flowering-sunflower': '6. Rozkwit',
  'fruit-development-cereals': '7. Rozwój owoców',
  'fruit-development-corn': '7. Rozwój owoców',
  'fruit-development-fruits': '7. Rozwój owoców',
  'fruit-development-grapes': '7. Rozwój owoców',
  'fruit-development-legumes': '7. Rozwój owoców',
  'fruit-development-rapeseed': '7. Rozwój owoców',
  'fruit-development-sorghum': '7. Rozwój owoców',
  'fruit-development-soybeans': '7. Rozwój owoców',
  'fruit-development-sunflower': '7. Rozwój owoców',
  'germination-cereals': '0. Kiełkowanie/rozwój pąków',
  'germination-corn': '0. Kiełkowanie/rozwój pąków',
  'germination-fruits': '0. Kiełkowanie/rozwój pąków',
  'germination-grapes': '0. Kiełkowanie/rozwój pąków',
  'germination-legumes': '0. Kiełkowanie/rozwój pąków',
  'germination-rapeseed': '0. Kiełkowanie/rozwój pąków',
  'germination-sorghum': '0. Kiełkowanie/rozwój pąków',
  'germination-soybeans': '0. Kiełkowanie/rozwój pąków',
  'germination-sugar-beet': '0. Kiełkowanie/rozwój pąków',
  'germination-sunflower': '0. Kiełkowanie/rozwój pąków',
  'grams': 'gr',
  'harvest_year': 'Rok zbioru',
  'hectares': 'ha',
  'hectoliters-per-hectare': 'hl/ha',
  'images': 'Zdjęcia',
  'inches': 'in',
  'inflorescence-cereals': '5. Pojawienie się kwiatostanu',
  'inflorescence-corn': '5. Pojawienie się kwiatostanu',
  'inflorescence-fruits': '5. Pojawienie się kwiatostanu',
  'inflorescence-grapes': '5. Pojawienie się kwiatostanu',
  'inflorescence-legumes': '5. Pojawienie się kwiatostanu',
  'inflorescence-rapeseed': '5. Pojawienie się kwiatostanu',
  'inflorescence-sorghum': '5. Pojawienie się kwiatostanu',
  'inflorescence-sunflower': '5. Pojawienie się kwiatostanu',
  'kilograms': 'kg',
  'kilograms-per-hectare': 'kg/ha',
  'leaf-development-cereals': '1. Rozwój liści',
  'leaf-development-corn': '1. Rozwój liści',
  'leaf-development-fruits': '1. Rozwój liści',
  'leaf-development-grapes': '1. Rozwój liści',
  'leaf-development-legumes': '1. Rozwój liści',
  'leaf-development-rapeseed': '1. Rozwój liści',
  'leaf-development-sorghum': '1. Rozwój liści',
  'leaf-development-soybeans': '1. Rozwój liści',
  'leaf-development-sugar-beet': '1. Rozwój liści',
  'leaf-development-sunflower': '1. Rozwój liści',
  'loss_cause': 'Przyczyna szkody',
  'meters': 'm',
  'ounces': 'oz',
  'percent': '%',
  'pln': 'zł',
  'policy_id': 'Numer polisy',
  'policy_number': 'Numer polisy',
  'ripening-cereals': '8. Dojrzewanie owoców i nasion',
  'ripening-corn': '8. Dojrzewanie owoców i nasion',
  'ripening-fruits': '8. Dojrzewanie owoców i nasion',
  'ripening-grapes': '8. Dojrzewanie owoców i nasion',
  'ripening-legumes': '8. Dojrzewanie owoców i nasion',
  'ripening-rapeseed': '8. Dojrzewanie owoców i nasion',
  'ripening-sorghum': '8. Dojrzewanie owoców i nasion',
  'ripening-soybeans': '8. Dojrzewanie owoców i nasion',
  'ripening-sunflower': '8. Dojrzewanie owoców i nasion',
  'senescence-fruits': '9. Starzenie się, początek spoczynku',
  'senescence-grapes': '9. Starzenie się, początek spoczynku',
  'senescence-sorghum': '9. Starzenie się, początek spoczynku',
  'senescence-soybeans': '9. Starzenie się, początek spoczynku',
  'senescence-sunflower': '9. Starzenie się, początek spoczynku',
  'side-shoot-cereals': '2. Tworzenie pędów bocznych / krzewienie',
  'side-shoot-sorghum': '2. Tworzenie pędów bocznych / krzewienie',
  'square-centimeters': 'cm²',
  'square-feet': 'ft²',
  'square-inches': 'in²',
  'square-meters': 'm²',
  'thousand-kernel-weight-grams': 'Masa 1000 ziaren (g)',
  'bags': 'sc',
  'tons-per-acre': 't/ac',
  'tons-per-hectare': 't/ha',
  'units-per-acre': '/ac',
  'units-per-ft2': '/ft²',
  'units-per-hectare': '/ha',
  'units-per-m2': '/m²',
  'usd': '$',
  'user-admin-static-data': 'Dane kraju',
  'user_group': 'Portfolio',
  'vegetative-growth-cereals': '3. Wydłużenie łodygi / wzrost rozety',
  'vegetative-growth-corn': '3. Wydłużenie łodygi / wzrost rozety',
  'vegetative-growth-fruits': '3. Wydłużenie łodygi / wzrost rozety',
  'vegetative-growth-legumes': '3. Wydłużenie łodygi / wzrost rozety',
  'vegetative-growth-rapeseed': '3. Wydłużenie łodygi / wzrost rozety',
  'vegetative-growth-sorghum': '3. Wydłużenie łodygi / wzrost rozety',
  'vegetative-growth-soybeans': '3. Wydłużenie łodygi / wzrost rozety',
  'vegetative-growth-sugar-beet': '3. Wydłużenie łodygi / wzrost rozety'
} as const;

export default function t(k: I18nSimpleKey|I18nParametric): string {
  if (typeof k == 'string' || typeof k == 'boolean') { return msgs[k]; }
  if (k === null || k == undefined) { console.error('t called with null or undefined!'); return '(∅)'; }
  switch (k.type) {
    case 'AddFarmTimeseriesMaxLimitError': return `Osiągnąłeś limit ${k.count} farm zapisanych w pamięci podręcznej offline. Aby kontynuować, usuń jeden.`;
    case 'AdjusterSampledFarm': return `Próbkowanie wykonane przez ${k.added_by} dla gospodarstwa ${k.farm_name}`;
    case 'AllocateRemainingHarvestArea': return `Możesz przydzielić kolejny ${intl_num_2(k.remainingArea)}` + t(k.unit) + `.`;
    case 'AreaIsKm2': return `Powierzchnia wybranego obszaru wynosi ${intl_num(k.area)} km² (około ${intl_int(k.mb)} MB).`;
    case 'CurFieldPolicyDesc': return `Obecna polisa działki: ${k.policy_number}`;
    case 'CurPortfolio': return `Portfolio: ${k.user_group}`;
    case 'DeleteAlreadyAssignedVisitWarning': return `Próbujesz usunąć wizytę przypisaną również do ${k.count} innych władz lokalnych.`;
    case 'DeletedUserX': return `Usunięto użytkownika „${k.user_name}”`;
    case 'DistKmAway': return `w odległości ${intl_num(k.dist_km)} km`;
    case 'DownloadExplain': return `Wybierz obszar do pobrania w trybie offline. Wszystkie dane mapy zostaną pobrane na każdym poziome powiększenia. Uwaga: jednorazowo w trybie offline możesz pobrać ${intl_int(k.limit_km_2)} km².`;
    case 'DuplicateFieldId': return `Pole ${k.external_field_id} zostało już dodane.`;
    case 'DuplicateSubplotWarning': return `Istnieje już Identyfikator wątku pobocznego: ${k.external_harvest_id} dla tej farmy i Rok zbioru. Aby dodać do niego próbkę, zmień swój wybór na poprzedniej stronie lub wybierz inny identyfikator wątku pobocznego.`;
    case 'EarsDetectedCount': return `Wykryto ${k.count} uszu`;
    case 'ErrorDeletingUserXY': return `Błąd podczas usuwania „${k.user_name}”: ${k.error_message}`;
    case 'EstimatedYieldOverrideInfo': return `Szacowany zysk został ostatnio zmieniony w dniu ${k.date}. Należy zauważyć, że to zastąpienie zastępuje zagregowaną szacowaną wydajność z poniższych próbek.`;
    case 'EstimatedYieldValueUnit': return `Szacowany: ${intl_num_2(k.val)}` + t(k.unit);
    case 'ExistingHarvestContainsSamples': return `Istniejące zbiory zawierają próbki (${k.count}).`;
    case 'ExoCropUnsupported': return `Ta funkcja obecnie nie obsługuje ` + t(k.crop) + `.`;
    case 'FeasibleYieldValueUnit': return `Prawdopodobny: ${intl_num_2(k.val)}` + t(k.unit);
    case 'FieldFormPickedOneOfMany': return `Ostrzeżenie: ta próbka zostanie dodana tylko do pierwszego z ${intl_int(k.numFields)} dodanych działek.`;
    case 'FileSizeTooBig': return `Ten plik jest za duży. Wybierz plik mniejszy niż ${k.file_size} MB.`;
    case 'ImageLocationTooFar': return `Zdjęcie nr${k.imageIdx} zostało zrobione ${intl_num(k.distanceMeters)}` + t(k.unit) + ` z przykładowej lokalizacji.`;
    case 'InsuredYieldValueUnit': return `Ubezpieczony: ${intl_num_2(k.val)}` + t(k.unit);
    case 'LossValue': return `Strata: ${intl_num(k.yield_loss_pct)}%`;
    case 'LossesMismatchWarning': return `Suma strat jest zbyt duża: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` powyżej szacunków.`;
    case 'LossesToAllocate': return `Straty do przydzielenia: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` z ${intl_num_2(k.total)}` + t(k.unit) + ` całkowitej straty.`;
    case 'NFarmsSelected': return `Wybrano farmy w liczbie ${intl_int(k.numFarms)}`;
    case 'NPoliciesSelected': return `Wybrano polisy w liczbie ${intl_int(k.numPolicies)}`;
    case 'NoAppInstalledToViewFile': return `Nie zainstalowano żadnej aplikacji, aby wyświetlić plik ${k.file_name}`;
    case 'NoDeleteClaim': return `Przed usunięciem tego roszczenia musisz usunąć jego ${intl_int(k.visitCount)} odwiedzin.`;
    case 'NoDeleteFarm': return `Przed usunięciem tej farmy musisz usunąć jej pola ${intl_int(k.fieldCount)}, próbki ${intl_int(k.sampleCount)} i oświadczenia ${intl_int(k.claimCount)}.`;
    case 'NoDeleteField': return `Przed usunięciem tego działki musisz usunąć jej próbki (${intl_int(k.sampleCount)}).`;
    case 'NoDeleteHarvest': return `Przed usunięciem tego zbioru musisz usunąć ${intl_int(k.sampleCount)} przypisanych do niego próbek.`;
    case 'NoDeletePolicy': return `Przed usunięciem tej zasady musisz usunąć jej roszczenia (${intl_int(k.claimCount)}).`;
    case 'NumPhotos': return `Zdjęcia (${intl_int(k.num_photos)})`;
    case 'PasswordResetSent': return `E-mail dotyczący zmiany hasła został wysłany na adres ${k.email}`;
    case 'PlantDensityValueUnit': return `Gęstość roślinności: ${intl_num(k.val)}` + t(k.unit);
    case 'PolicyDesc': return `Polisa ${k.policy_number}`;
    case 'ReportContainsTooManyFields': return `Ten raport zawiera za dużo danych (${intl_int(k.numFields)} działek). Rozważ wybór konkretnych dat próbkowania i zbiorów.`;
    case 'ReportNoHarvests': return `To gospodarstwo posiada (${intl_int(k.numFields)}) działek, ale nie ma żadnych informacji o zbiorach do zaraportowania!`;
    case 'ResetMsg': return `E-mail z linkiem do zmiany został wysłany na adres ${k.email}`;
    case 'SampleAbrv': return `${intl_int(k.count)} s.`;
    case 'SampleLocationTooFar': return `Przykładowa lokalizacja to ${intl_num_2(k.val)}` + t(k.unit) + ` od Twojej lokalizacji początkowej. Jeśli chcesz użyć swojej lokalizacji, naciśnij „Użyj mojej lokalizacji” powyżej.`;
    case 'SampleYieldWarning': return `Jeśli to możliwe, wypełnij kolumnę ${k.column1} lub kolumnę ${k.column2}.`;
    case 'SearchAddTipDesc': return `Jeśli chcesz wyszukać wiele terminów (np. zbiory pszenicy w portfolio ${k.firstUserGroup}), możesz nacisnąć ikonę ⊕, aby dodać kolejny termin.`;
    case 'SearchExampleDescUserGroup': return `Wpisz nazwę portfolio (np. ${k.firstUserGroup}), aby wyświetlić wszystkie zawarte w nim dane.`;
    case 'SearchFoundCount': return `Znaleziono gospodarstwa (${intl_int(k.farmCount)}), działki (${intl_int(k.fieldCount)}) i próbki (${intl_int(k.sampleCount)}).`;
    case 'SearchInfoText': return `Możesz znaleźć gospodarstwa, działki, próbki i polisy, wyszukując je tutaj. Jeśli chcesz wyszukać wiele terminów (np. zbiory pszenicy w portfolio ${k.firstUserGroup}), możesz nacisnąć ikonę ⊕. Każde wyszukiwanie będzie wtedy działać jak filtr, który dodatkowo zawęża obszar wyników. Podanie poniżej przykłady pomogą Ci w zapoznaniu się z niektórymi sposobami wyszukiwania.`;
    case 'SearchResultAddedBy': return `próbki dodane przez ${k.id}`;
    case 'SearchResultAddress': return `Wyśrodkuj mapę na ${k.address}`;
    case 'SearchResultCropCondition': return `próbki upraw o stanie ${k.id}`;
    case 'SearchResultFarm': return `gospodarstwo ${k.farmDesc}`;
    case 'SearchResultHarvestCrop': return `dane dla ${k.id}`;
    case 'SearchResultHarvestSeason': return `dane o zbiorach w ` + t(k.id);
    case 'SearchResultHarvestYear': return `dane dla zbiorów z ${k.id}`;
    case 'SearchResultUserGroup': return `Portfolio ${k.id}`;
    case 'SelectCount': return `Wybrano ${k.count}`;
    case 'SortBy': return `Sortuj ${k.column} ` + t(k.id);
    case 'SureAddAnotherHarvest': return `Czy na pewno chcesz dodać kolejne zbiory do tej działki oprócz ${k.harvestDesc}?`;
    case 'SureDeleteFile': return `Czy na pewno chcesz usunąć ${k.file_name}?`;
    case 'SureDeletePolicy': return `Czy na pewno chcesz usunąć tę polisę? Ta polisa zostanie usunięta z ${intl_int(k.fieldCount)} działek i ${intl_int(k.sampleCount)} próbek, ale pozostałe dane nie zostaną utracone.`;
    case 'SureRemoveFarm': return `Czy na pewno chcesz usunąć to gospodarstwo i ${intl_int(k.fieldCount)} przypisanych działek?`;
    case 'SureRemoveSelectedOfflineFarms': return `Czy na pewno chcesz usunąć dostęp offline dla ${k.count} wybranych gospodarstw?`;
    case 'TelepacReportEmail': return `Raport z importu został wysłany do Ciebie na adres ${k.email}, możesz go również pobrać tutaj.`;
    case 'TotalAffectedAreaHarvest': return `Całkowity obszar dotknięty tymi zbiorami wyniesie ${intl_num_2(k.newArea)}` + t(k.unit) + ` na obszarze zbiorów wynoszącym ${intl_num_2(k.harvestArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt': return `Aktualnie dotknięty obszar (próbki z ostatnich dwóch tygodni) w przypadku tych zbiorów to: ${intl_num_2(k.curArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt2': return `Oto wszystkie obszary dotknięte tymi zbiorami: ${k.arr}.`;
    case 'TotalLossPct': return `Całkowita szkoda: ${intl_num(k.totalLossPct)}%`;
    case 'TotalSubplotAreaBiggerThanField': return `Całkowita powierzchnia działki cząstkowej (${intl_num_2(k.total_subplot_area)} ha) jest większa niż powierzchnia pola (${intl_num_2(k.field_area)} ha).`;
    case 'UpdateHarvestAreaMsg': return `Spowoduje to zaktualizowanie Obszar uprawy bieżącego zbioru (${k.desc}) do ${intl_num_2(k.val)}` + t(k.unit) + `.`;
    case 'UpgradeFieldHarvest': return `Na tym polu znajdują się obecnie zbiory w wysokości ${k.originalHarvest}, które zgodnie z umową zostaną zastąpione zbiorami w wysokości ${k.upgradedHarvest}.`;
    case 'WarnHarvestAlreadyInUse': return `Zapisanie zmian w istniejących zbiorach będzie miało wpływ na ${intl_int(k.num_samples)} już istniejących próbek.`;
    case 'WarningDupePolicyNumber': return `Uwaga: istnieje inna polisa o numerze ${k.policy_number}`;
    case 'YieldMismatchWarning': return `Nie wszystkie szkody zostały uwzględnione: brakuje ${intl_num_2(k.deltaVal)}` + t(k.unit) + `.`;
    case 'YourAreGoingToDeleteUsers': return `Zamierzasz usunąć ${intl_int(k.num_users)} użytkowników:`;
    case 'downloadingLayers': return `Pobieranie (${intl_int(k.count)}) warstw map do użytku w trybie offline.`;
    case 'uploadingImages': return `Przesyłanie zdjęć dla próbki do bazy danych (zostało ${intl_int(k.left)}).`;
    case 'AreaUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'AreaUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
    case 'FarmDesc': return `${k.farm_name}, ${k.address}`;
    case 'FarmDesc_reference': return `${k.farm_name}, ${k.address} (${k.external_farm_id})`;
    case 'SampleDesc': return `${k.sample_date}: ${intl_num(k.estimated_yield_val)}` + t(k.estimated_yield_unit) + ` ` + t(k.crop_condition);
    case 'ValueUnit': return `${intl_num(k.val)}` + t(k.unit);
    case 'ValueUnitRange': return `${intl_num(k.min_val)}-${intl_num(k.max_val)}` + t(k.unit);
    case 'YieldUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'YieldUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
  }
}