// @formatter:off
import {intl_int, intl_num, intl_num_2, intl_num_4} from './i18n-util';
import {I18nParametric, I18nSimpleKey} from './i18n';

const msgs = {
  '1990': '1990',
  '1991': '1991',
  '1992': '1992',
  '1993': '1993',
  '1994': '1994',
  '1995': '1995',
  '1996': '1996',
  '1997': '1997',
  '1998': '1998',
  '1999': '1999',
  '2000': '2000',
  '2001': '2001',
  '2002': '2002',
  '2003': '2003',
  '2004': '2004',
  '2005': '2005',
  '2006': '2006',
  '2007': '2007',
  '2008': '2008',
  '2009': '2009',
  '2010': '2010',
  '2011': '2011',
  '2012': '2012',
  '2013': '2013',
  '2014': '2014',
  '2015': '2015',
  '2016': '2016',
  '2017': '2017',
  '2018': '2018',
  '2019': '2019',
  '2020': '2020',
  '2021': '2021',
  '2022': '2022',
  '2023': '2023',
  '2024': '2024',
  '2025': '2025',
  '2026': '2026',
  '2027': '2027',
  '2028': '2028',
  '2029': '2029',
  '2030': '2030',
  '2031': '2031',
  '2032': '2032',
  '2033': '2033',
  '2034': '2034',
  '2035': '2035',
  'ARG': 'Argentina',
  'AUS': 'Australia',
  'AUT': 'Austria',
  'Accept': 'Accept',
  'AcceptOrDecline': 'Accepta/refuza',
  'AddAnotherArea': 'Adăugați o altă zonă',
  'AddAssignee': 'Adăugați cesionar',
  'AddAttachments': 'Adăugați atașamente',
  'AddCause': 'Adăugați o cauză',
  'AddClaim': 'Adăugați revendicare',
  'AddCost': 'Adăugați un cost',
  'AddCrop': 'Adăugați tipul de decupare',
  'AddEditSampleShape': 'Desenați zona afectată pe hartă',
  'AddEditor': 'Adăugați editor',
  'AddEditors': 'Adăugați editori',
  'AddEditorsOneGroup': 'Vă rugăm să adăugați editori la entitățile care aparțin unui singur portofoliu',
  'AddFarm': 'Adăugați o fermă',
  'AddFarmInfo': 'Vă rugăm să rețineți că pentru a adăuga parcele noi/suplimentare la o fermă EXISTENTĂ, este necesar să o căutați mai întâi după Numele fermei sau Referința fermei în câmpurile corespunzătoare de mai jos.',
  'AddField': 'Adăugați o parcela nouă',
  'AddFieldShape': 'Adăugați perimetrul parcelei',
  'AddFieldShapes': 'Adăugați perimetrul parcelei',
  'AddFieldsHarvests': 'Adăugați câmpuri și recolte',
  'AddFromFileManager': 'Adăugați din File Manager',
  'AddFromPhotos': 'Adăugați din fotografii',
  'AddGuaranteedLossCause': 'Adăugați o cauză de pierdere garantată',
  'AddHarvest': 'Adăugați campania nouă',
  'AddLossCause': 'Adăugați o cauză de pierdere',
  'AddNewDamage': 'Adăugați noi daune',
  'AddNewHarvest': 'Creați o campania agricolă',
  'AddNewHarvestFor': 'Creați o nouă campania agricolă pentru',
  'AddNewLoss': 'Adăugați o nouă pierdere',
  'AddNewSubparcel': 'Adăugați o subparcela nouă',
  'AddOrEditFields': 'Adăugați/editați câmpuri',
  'AddPhoto': 'Adăugați o fotografie',
  'AddPhotoError': 'Adăugarea fotografiei a eșuat',
  'AddPolicy': 'Adăugați un contract nou',
  'AddRecipient': 'Adauga recipient',
  'AddSample': 'Adăugați un eșantion',
  'AddSamplePhoto': 'Adăugați o fotografie la eșantion',
  'AddSubplotId': 'Adăugați ID subplot',
  'AddUnguaranteedLossCause': 'Adăugați o cauză de pierdere negarantată',
  'AddUser': 'Adăugați utilizator',
  'AddVarietyHarvest': 'Adăugați varietate',
  'AddedBy': 'Adăugat de',
  'AddedGroups': 'Grupuri adăugate',
  'AddedOn': 'Adăugat pe',
  'AdditionalInfo': 'Info suplimentare',
  'AdditionalQuestions': 'Întrebări suplimentare',
  'AdvancedOptions': 'Opțiuni avansate',
  'AffectedArea': 'Suprafata afectată',
  'AffectedCrop': 'Cultură afectată',
  'AffectedCrops': 'Culturile afectate',
  'AffectedCropsRequiredToDeclareLoss': 'Trebuie să selectați cel puțin o cultură afectată pentru a adăuga pierderile declarate.',
  'AffectedField': 'Câmpul lovit',
  'All': 'Toate',
  'AllSynced': 'Toate sincronizate',
  'AllTime': 'Întotdeauna',
  'AlsoInheritedFrom': 'Moștenit și de la',
  'Amount': 'Suma ($)',
  'AppVersionDeprecated': 'Versiunea aplicației este depreciată',
  'AppVersionDeprecatedMessage': 'Versiunea aplicației dvs. este învechită. Trebuie să reîmprospătați pagina pentru a obține noua versiune. Faceți clic pe REFRESH pentru a reîmprospăta pagina. Dacă aveți date nesalvate, faceți clic pe Anulare și copiați manual datele, apoi reîmprospătați pagina.',
  'Area': 'Suprafața',
  'AreaCropTitle': 'Suprafață pe cultură',
  'Ascending': 'ascendent',
  'Assign': 'Atribui',
  'AssignExistingFields': 'Alocați parcele existente',
  'AssignedLossAdjusters': 'Regulatori de pierderi alocați',
  'AssignedTo': 'Atribuit',
  'AssociatedEmails': 'E-mail-uri asociate',
  'Attachment': 'Atașament',
  'Attachments': 'Atasamente',
  'Average': 'Medie',
  'AverageFieldArea': 'Suprafața medie a câmpului',
  'AvgBollsPerPlant': 'Numărul mediu de boluri pe plantă',
  'AvgEarsPerPlant': 'Numărul mediu de spice pe plantă',
  'AvgGrainsPerEar': 'Numărul mediu de boabe pe spic',
  'AvgGrainsPerPod': 'Numărul mediu de boabe per păstaie',
  'AvgGrainsPerSilique': 'Numărul mediu de boabe pe silic',
  'AvgKernelsPerBoll': 'Numărul mediu de sâmburi per bol',
  'AvgPodsPerShaft': 'Numărul mediu de păstăi pe arbore',
  'AvgSiliquesPerShaft': 'Numărul mediu de silice pe arbore',
  'BEL': 'Belgia',
  'BRA': 'Brazilia',
  'Back': 'Înapoi',
  'BackToHome': 'Înapoi la pagina de pornire',
  'Benchmark': 'Valoare de referință',
  'Biomass': 'Biomasă',
  'BunchWeight': 'Greutate medie pe ciorchine de struguri în grame',
  'BunchesPerPlant': 'Numărul mediu de ciorchini de struguri pe plantă',
  'ByCrop': 'După recoltă',
  'ByField': 'După domeniu',
  'ByFieldArea': 'După suprafața câmpului',
  'ByFieldCount': 'După numărul de câmpuri',
  'CAN': 'Canada',
  'CHE': 'Elveția',
  'CZE': 'Cehia',
  'Calculate': 'calculati',
  'CalculateAbbreviated': 'Calc.',
  'Cancel': 'Anulați',
  'Change': 'Schimbați',
  'ChangeOverrideSampleMsg': 'Sigur doriți să modificați valoarea de înlocuire pentru această decupare?',
  'ChangeUserAccessRights': 'Modificați drepturile de acces ale utilizatorilor',
  'CheckForCodepushUpdate': 'Verifică pentru actualizări',
  'CirclesArea': 'Zona Cercului',
  'CirclesDiameter': 'Diametrul cercului',
  'CirclesRadius': 'Raza cercului',
  'Claim': 'Claim',
  'ClaimNumber': 'Numar de daune',
  'ClaimRequests': 'Cereri de revendicare',
  'ClaimStatus_adjuster-accepted': 'Ajustator acceptat',
  'ClaimStatus_assessment-in-progress': 'Evaluare în curs',
  'ClaimStatus_awaiting-adjuster-acceptance': 'Se așteaptă acceptarea ajustatorului',
  'ClaimStatus_completed': 'Efectuat',
  'ClaimStatus_created-unassigned': 'Creat și nealocat',
  'ClaimStatus_payment-proposal-sent': 'Propunere de plată trimisă',
  'ClaimStatus_rejected': 'Respins',
  'ClaimStatus_visit-date-defined': 'Data vizitei a fost definită',
  'ClaimStatus_visit-report-created': 'Raport de vizită creat',
  'ClaimStatus_visit-samples-collected': 'Vizita a început',
  'Claims': 'Creanțe',
  'Clear': 'Ștergeți',
  'ClearAll': 'Ștergeți tot',
  'Clearing': 'Ștergere',
  'ClickHereToAddFarmerEmail': 'Faceți clic aici pentru a adăuga e-mailul fermierului',
  'ClickUpload': 'Faceți clic pentru a încărca',
  'Close': 'Închide',
  'CloseVisit': 'Inchideti vizita',
  'Closed': 'Inchis',
  'ClosedVisits': 'Vizite închise',
  'CloudCover': 'Plafon de nori',
  'CloudCoverInfo': 'Această coloană arată elementele evidențiate dacă vreun an a fost prea tulbure pentru o măsurare fiabilă a productivității (> 50% din săptămânile ascunse în perioada de vârf)',
  'CloudCoverWarning': 'Avertizare privind acoperirea norilor',
  'CommentInfoWarning': 'Avertizare! Nu apare pe rapoarte.',
  'Comments': 'Comentarii',
  'CompassHeading': 'Titlu',
  'CompensableLosses': 'Pierderi compensabile',
  'Condition': 'Condiție',
  'Configuration': 'Configurație',
  'Confirm': 'A confirma',
  'Contact': 'Contactați-ne',
  'ContainsCopernicus': 'Conține informații despre Serviciul Copernicus (2017)',
  'ContinueWithErrorsAndWarnings': 'Continuați fără a remedia erorile și avertismentele?',
  'ConversionRatio': 'Raportul de conversie',
  'CopyCurrentHarvest': 'Copiați recolta curentă',
  'CopyHarvestFromPreviousYears': 'Copiați recolta din anii anteriori',
  'CopyLink': 'Copiați linkul',
  'CopySample': 'Copiați eșantionul',
  'CopyVisit': 'Copiați vizita',
  'CostType': 'Tip de cost',
  'Costs': 'Costuri ($)',
  'CouldntDelete': 'Imposibil de șters acest element. Trebuie să fiți online pentru a șterge.',
  'CreateNewVisit': 'Creați o nouă vizită',
  'CropCondition': 'Condiţii culturi',
  'CropConditionBreakdown': 'Distribuția condițiilor de cultură',
  'CropCoverage': 'Acoperirea culturilor',
  'CropCoverageInfo': 'Acesta arată procentul din câmpul acoperit de cultura de interes',
  'CropType-s': 'Tip de cultură',
  'CropVariety': 'Soi',
  'CropYieldAverageTitle': 'Randament mediu estimat',
  'CurHarvest': 'Campania curentă',
  'CurrentFilters': 'Filtrele activate',
  'CurrentVisit': 'Vizită curentă',
  'CurrentVisits': 'Vizite curente',
  'Customer': 'Client',
  'DEU': 'Germania',
  'DamagedFields': 'Câmpuri deteriorate',
  'DamagedHarvest': 'Recolta deteriorată',
  'Damages': 'Daune',
  'Dashboard': 'Tablou de bord',
  'Date': 'Data',
  'Date-s': 'Date',
  'DateRange': 'Interval de date',
  'DeadPlantsPct': 'Plante moarte (%)',
  'DeclaredHazard': 'Risc declarat',
  'DeclaredLossCauses': 'Cauze de pierdere declarate',
  'Decline': 'Declin',
  'Deductible': 'Deductibil',
  'Deductible-s': 'Deductibil(e)',
  'Delete': 'Ștergeți',
  'DeleteForEveryone': 'Ștergeți pentru toată lumea',
  'DeleteOverrideSampleMsg': 'Sigur doriți să ștergeți estimarea de anulare? Dacă da, vom reveni la eșantioane pentru a obține valoarea estimată agregată la nivel de cultură.',
  'DeleteUser': 'Ștergeți utilizatorul',
  'DeleteUsers': 'Ștergeți utilizatori',
  'Delimiter': 'Delimitator',
  'Descending': 'Descendentă',
  'DetachedSample-s': 'Eșantion(e) detașat(e)',
  'Details': 'Detalii',
  'DetectDensity': 'Detectează densitatea',
  'DisablePoiMode': 'Afișați toate fermele',
  'Done': 'Terminat',
  'Download': 'Descărcați',
  'DownloadGeojson': 'Descărcați fișierul GeoJSON',
  'DownloadXLSX': 'Descărcați fișierul Excel',
  'Downloading': 'Descărcare în curs',
  'DownloadingInitialData': 'Descărcarea datelor inițiale',
  'Draw': 'Desenați',
  'DuplicateEntryWasRemoved': 'Următoarea intrare există deja și nu va fi importată:',
  'ESP': 'Spania',
  'EarDensity': 'Densitatea spicului',
  'Edit': 'Editați',
  'EditClaim': 'Editați revendicarea',
  'EditFarm': 'Editați ferma',
  'EditField': 'Editați parcela',
  'EditFieldShape': 'Editați perimetrul',
  'EditHarvest': 'Editați recolta',
  'EditPolicy': 'Editați contractul',
  'EditSample': 'Editați eșantionul',
  'Editors': 'Editori',
  'Email': 'E-mail',
  'EmailMissing': 'Vă rugăm să introduceți e-mailul',
  'Emergence': 'Apariție',
  'EmergenceDate': 'Data de aparitie',
  'EnableNow': 'Activați acum',
  'EnablePoiMode': 'Afișați numai ferme interesante',
  'EnablePreciseLocation': 'Vă rugăm să activați Locația precisă',
  'EndDate': 'Data de încheiere',
  'Entity': 'Entitate',
  'ErrMissingFields': 'Vă rugăm să completați toate câmpurile subliniate de mai sus.',
  'Error': 'Eroare',
  'EstimatedLossAbbr': 'Pierderi estimate',
  'EstimatedYield': 'Randament estimat',
  'EstimatedYieldAbbr': 'Randament estimat',
  'EstimatedYieldAreaWeighted': 'Randament estimat (ponderat pe suprafață)',
  'EstimatedYieldArithmeticMean': 'Randament estimat (media aritmetică)',
  'EstimatedYieldLoss': 'Pierdere estimată a randamentului',
  'Estimation': 'Estimare',
  'EventDate': 'Data evenimentului',
  'EventValidation': 'Validarea evenimentului',
  'Existing': 'Existent',
  'ExistingClaim': 'Revendicare existentă',
  'ExistingClaimMsg': 'Există o cerere existentă pentru această fermă. Doriți să continuați cu revendicarea existentă?',
  'ExistingFarm': 'Această fermă există deja.',
  'ExoBinUnsupported': 'Această funcție nu este acceptată în prezent pe această versiune a aplicației. Actualizați aplicația la cea mai recentă versiune.',
  'ExoCamera': 'Cameră Exo',
  'ExoDetectionError': 'Nu pot detecta urechile. Încercați din nou ajustând poziția sau captând o nouă imagine.',
  'ExoModelNotDownloaded': 'Modelul Exo nu a fost descărcat. Vă rugăm să vă conectați la internet pentru a descărca modelul pentru utilizare offline.',
  'ExoNotEnabledForUser': 'Această funcție nu este activată pentru compania sau grupul dvs. de utilizatori. Vă rugăm să contactați administratorul dvs.',
  'ExoTutorialPanDescription': 'Deplasați imaginea folosind un deget.',
  'ExoTutorialPinchDescription': 'Ciupiți imaginea pentru a mări și micșora.',
  'ExoTutorialSearchDescription': 'Aliniați zona de detectare cu partea dorită a imaginii, până când aceasta devine verde.',
  'Expert': 'Expert',
  'Expert-s': 'Experți',
  'ExpertName': 'Numele expertul',
  'ExpertRanking': 'Clasamentul experților',
  'ExpertReport': 'Raport expert',
  'ExternalClaimId': 'Id-ul revendicării externe',
  'ExternalFieldId': 'ID câmp extern',
  'ExtremelyHighTemperature': 'Val de căldură extremă (Tmax>38°C)',
  'ExtremelyLowTemperature': 'Risc extrem de îngheț (-15°C>Tmin)',
  'FRA': 'Franța',
  'FailedToSyncChanges': 'Nu s-au sincronizat modificările',
  'Farm': 'Fermă',
  'FarmAddress': 'Adresa fermei',
  'FarmAndHarvest': 'Informații despre fermă și recoltă',
  'FarmDeletedPleaseStartOver': 'Ferma a fost ștearsă! Vă rog să începeți de la capăt',
  'FarmFormComment': 'Informații suplimentare de funcționare',
  'FarmHarvestLevel': 'La nivel de ferma',
  'FarmHarvestLevelObservation': 'Observație la nivel de recoltă',
  'FarmHarvests': 'Campanii',
  'FarmInfo': 'Informații despre fermă',
  'FarmLocationPrompt': 'Apăsați lung pe hartă pentru a seta locația fermei sau apăsați butonul abastru din stânga pentru a utiliza locația dvs.',
  'FarmName': 'Numele fermei',
  'FarmReference': 'Referință fermă',
  'Farmer': 'Fermier',
  'FarmerName': 'Numele fermierul',
  'FarmerOrRepresentative': 'Fermier sau reprezentant',
  'FarmerRepresentative': 'Reprezentant fermier',
  'FarmersEstimatedYieldLoss': 'Pierderea estimată a randamentului fermierului',
  'Farms': 'Ferme',
  'FeasibleYield': 'Randament fezabil',
  'FeasibleYieldEmpty': 'Randamentul fezabil este gol.',
  'Feedback': 'Feedback',
  'FetchReportOfflineInfo': 'Se pare că sunteți offline, prin urmare raportul nu poate fi preluat.',
  'Field': 'Parcelă',
  'Field-s': 'Câmpuri)',
  'FieldArea': 'Suprafața parcele',
  'FieldAreaAndReference': 'Suprafata parcelei și referință',
  'FieldCultivatedArea': 'Suprafața cultivată (câmp)',
  'FieldFormComment': 'Informații suplimentare despre parcelă',
  'FieldHarvests': 'Recoltele de câmp',
  'FieldInfo': 'Informații despre parcela',
  'FieldLevel': 'La nivel de parcelă',
  'FieldLocationPrompt': 'Apăsați lung pe hartă pentru a seta locația parcelei sau apăsați butonul albastru din stânga pentru a utiliza locația dvs.',
  'FieldManagement': 'Managementul terenului',
  'FieldMissingCurPolicyHarvest': 'Acestă parcela nu are un contract sau o recoltă atribuită; faceți clic aici pentru a atribui informații.',
  'FieldReference': 'Referință parcelă',
  'FieldScoring': 'Punctajul câmpului',
  'FieldShapeAndFarm': 'Perimetrul parcelă și fermă',
  'Fields': 'Parcele',
  'FileActions': 'Fișierează acțiuni',
  'FilterList': 'Filtrați lista',
  'Filters': 'Filtre',
  'FirstAndLastName': 'Nume și prenume',
  'FirstName': 'Nume',
  'FloodZone': 'Zona inundabila',
  'ForgotPassword': 'Resetați parola',
  'FormSubmitted': 'Salvat!',
  'FrontPageReportDisclaimer': '-',
  'FungicideApplied': 'Aplicat fungicid',
  'GPSModuleUnavailable': 'Modul GPS indisponibil',
  'Generate': 'Generați',
  'GenerateReport': 'Generați raport',
  'GeneratingReportPleaseWait': 'Raport în curs de creare, vă rugăm să așteptați...',
  'GeographicalArea': 'Arie geografică',
  'GetDirectionsField': 'Obțineți indicații de orientare către parcela',
  'GrainMoisture': 'Umiditatea cerealelor',
  'GrainMoistureReduction': 'Reducere datorită umidității cerealelor',
  'GrantsTo': 'Granturi pentru',
  'GrapeVariety': 'Soi de struguri',
  'GrossSampleProduction': 'Producția brută de eșantion',
  'GuaranteedLosses': 'Pierderi garantate',
  'HUN': 'Ungaria',
  'HailInfo': 'Acest strat afișează riscul evenimentelor de grindină.',
  'Harvest': 'Recoltă',
  'HarvestArea': 'Suprafaţă cultivată',
  'HarvestAreaMustBePositiveAndLessThanFieldArea': 'Suprafața de recoltare trebuie să fie pozitivă și nu mai mare decât suprafața câmpului!',
  'HarvestFormComment': 'Informații suplimentare despre recoltare',
  'HarvestInfo': 'Informații despre recoltă',
  'HarvestLoss': 'Pierderea recoltei',
  'HarvestModificationDisabledMsg': 'Această recoltă nu poate fi modificată, deoarece a fost creată de către asigurător.',
  'HarvestPlotId': 'ID subplot',
  'HarvestPractice': 'Tip de cultură',
  'HarvestReference': 'Referință pentru recoltare',
  'HarvestYear': 'Anul recoltei',
  'Harvester': 'Secerator',
  'HarvesterDistanceHarvested': 'Distanța recoltată',
  'HarvesterPlatformWidth': 'Platformă Lățimea de recoltare',
  'Harvests': 'Recolte',
  'HarvestsWillNotBeUpdated': 'Recoltele din acest câmp nu vor fi actualizate.',
  'HelloReportIssue': 'Bună ziua, aș dori să raportez următoarea problemă cu aplicația GreenTriangle:',
  'HerbicideApplied': 'Erbicid aplicat',
  'HereAddAsNew': 'Faceți clic aici pentru a adăuga o fermă nouă.',
  'HideDataFromMap': 'Ascundere datele de pe hartă',
  'HighHailRisk': 'Risc ridicat de daune din cauza grindinei',
  'HighPWind': 'Furtună (28.5-32.5 m/s)',
  'HighPrecipitation': 'Precipitații mari (50-75mm/săp)',
  'HighSoilMoisture': 'Puțin mai umed decât de obicei',
  'HighTemperature': 'Val de căldură ușor (25°C<Tmax<29°C)',
  'HighVegetation': 'Sănătatea vegetației este puțin mai bună decât de obicei (+25%)',
  'Higher': 'Superior',
  'HigherSoilMoisture': 'Mai umed decât de obicei',
  'HigherTemperature': 'Val de căldură moderat (29°C<Tmax<34°C)',
  'HigherVegetation': 'Sănătatea vegetației este mai bună decât de obicei (+40%)',
  'Highest': 'Cel mai bun',
  'HighestSoilMoisture': 'Mult mai umed decât de obicei',
  'HighestTemperature': 'Val de căldură puternic (Tmax>34°C)',
  'HighestVegetation': 'Sănătatea vegetației este mult mai bună decât de obicei (+55%)',
  'HistoricalDeclaredYields': 'Randamente declarate istorice',
  'HistoricalObservedYields': 'Randamente observate istoric',
  'HistoricalRealizedYields': 'Producţia istorică realizată',
  'Homogeneity': 'Omogenitate',
  'HomogeneityInfo': 'Scorul de omogenitate măsoară cât de omogen a fost câmpul din punct de vedere istoric în ceea ce privește dezvoltarea culturilor',
  'ID': 'ID',
  'ITA': 'Italia',
  'ImportData': 'Importați date',
  'Impurities': 'Impurităţi',
  'Info': 'Info',
  'InheritedFrom': 'Moștenit de la',
  'Initials': 'Inițiale',
  'InsecticideApplied': 'Insecticid aplicat',
  'Insee': 'INSEE',
  'Install': 'Instalare',
  'InsuredArea': 'Suprafaţă asigurată',
  'InsuredCropRequired': 'Selectați cel puțin o cultură asigurată pentru a continua.',
  'InsuredCrops': 'Culturi Asigurate',
  'InsuredPrice': 'Preţul asigurat',
  'InsuredProduction': 'Productie asigurata',
  'InsuredYield': 'Randament asigurat',
  'InterfieldInfo': '„Compararea parcele” afișează starea absolută de sănătate a vegetației și permite compararea directă a stării generale a vegetației între diferite parcele.',
  'InteryieldInfo': 'Predicția randamentului afișează randamentul estimat pe baza indicatorilor de satelit și a datelor meteo disponibile până la data selectată.',
  'IntrafieldInfo': 'Variabilitatea întra-parcelara afișează starea normală a vegetației pentru parcela care este monitorizată. Parcela este împărțită în 5 zone cu suprafață egală corespunzătoare a 20 % (quintile) din valorile nominale, de la cea mai mică la cea mai mare',
  'InvalidShapeTraced': 'Forma dvs. nu poate avea linii care se intersectează. Vă rugăm să desenați o formă simplă fără încrucișări.',
  'Irrigated': 'Irigat',
  'IrrigatedQuestionMark': 'Irigat?',
  'KernelsPerEar': 'Boabe pe spic',
  'KernerlsPerUnit': 'Sâmburi pe unitate',
  'LTU': 'Lituania',
  'LastChanged': 'Ultima modificare',
  'LastName': 'Nume',
  'Legend': 'Legendă',
  'LessDetails': 'Mai puține detalii',
  'LinkCopiedToClipboard': 'Linkul a fost copiat în clipboard',
  'LinkToThisLocation': 'Link către această locație',
  'List': 'Listă',
  'LiveSampleFeed': 'Eșantionare live',
  'Loading': 'Se încarcă',
  'Locale': 'Limba',
  'Location': 'Locație',
  'LocationAndFarm': 'Locație și fermă',
  'LocationMocked': 'Se pare că locația ta este batjocorită. Vă rugăm să utilizați locația dvs. reală pentru a continua.',
  'LocationNotProvided': 'Locația trebuie să fie activată pentru a utiliza această funcționalitate',
  'Login': 'Autentificare',
  'Logout': 'Logout',
  'LossAdjuster': 'Inspector',
  'LossCause': 'Cauza pierderii',
  'LossCauseRecognized': 'Cauza pierderii recunoscută',
  'LossEstimation': 'Pierdera estimatӑ',
  'LossEventDate': 'Data evenimentului pierderii',
  'Losses': 'Pierderi',
  'LowHailRisk': 'Risc scăzut de daune din cauza grindinei',
  'LowPrecipitation': 'Precipitații reduse (15-30mm/săp)',
  'LowSoilMoisture': 'Puțin mai uscat decât de obicei',
  'LowTemperature': 'Risc ușor de îngheț (-1°C<Tmin<2°C)',
  'LowVegetation': 'Sănătatea vegetației este puțin mai slabă decât de obicei (-25%)',
  'LowWind': 'Vânt moderat (17-24.5 m/s)',
  'Lower': 'Inferior',
  'LowerSoilMoisture': 'Mai uscat decât de obicei',
  'LowerTemperature': 'Risc moderat de îngheț (-5°C<Tmin<-1°C)',
  'LowerVegetation': 'Sănătatea vegetației este mai slabă decât de obicei (-40%)',
  'Lowest': 'Cel mai slab',
  'LowestSoilMoisture': 'Mult mai uscat decât de obicei',
  'LowestTemperature': 'Risc puternic de îngheț (-5°C>Tmin)',
  'LowestVegetation': 'Sănătatea vegetației este mult mai slabă decât de obicei (-55%)',
  'MAR': 'Maroc',
  'MDA': 'Moldova',
  'MEX': 'Mexic',
  'ManageUserAccountAndSettings': 'Gestionați-vă conturile și setările',
  'Manager': 'Administrator',
  'Map': 'Hartă',
  'MeasureDensity': 'Măsurați densitatea',
  'Median': 'Median',
  'Merge': 'Fuzionare',
  'Method': 'Metodă',
  'MixedTemperature': 'Zonă de fluctuație ridicată a temperaturii',
  'ModerateHailRisk': 'Risc moderat de daune din cauza grindinei',
  'ModeratePrecipitation': 'Precipitații moderate (30-50mm/săp)',
  'ModerateWind': 'Vânt puternic (24.5-28.5 m/s)',
  'Months': 'Luni',
  'MoreDetails': 'Mai multe detalii',
  'MoreSpecificHarvestDetected': 'Recolta mai specifică detectată și va înlocui recolta existentă.',
  'MunicipalMedianVegetation': 'Vegetația Mediană a municipiului',
  'Municipality': 'Municipiul',
  'MustAddCustomer': 'Trebuie să adăugați cel puțin un client',
  'MustAddEmail': 'Trebuie să furnizați un e-mail valid',
  'MustSelectACustomer': 'Trebuie să selectați un client pentru a continua',
  'MustSelectCropAndYear': 'Trebuie să selectați o recoltă și un an',
  'NDVIRange': 'Gama NDVI',
  'NationalYield': 'Productivitate națională',
  'NeedCurrentHarvest': 'Trebuie să adăugați o cultură și o campania pentru acest sezon înainte de a continua.',
  'NeedHelpContact': 'Probleme cu conectarea? - Contactați-ne',
  'New': 'Nou',
  'NewValue': 'Valoare nouă',
  'NewVersionWarning': 'Este disponibilă o nouă versiune a aplicației.',
  'NewVisit': 'Vizită nouă',
  'Next': 'Continuare',
  'No': 'Nu',
  'NoClaims': 'Fără pretenții',
  'NoData': 'Nu s-au gasit date.',
  'NoDataFoundForYourFilters': 'Nu avem date pentru criteriile dvs. actuale de filtrare.',
  'NoFarmEditors': 'Nu există editori alocați acestei ferme',
  'NoGpsSupport': 'Acest dispozitiv nu are o unitate GPS.',
  'NoHailRisk': 'Fără risc de deteriorare',
  'NoOfflineFarms': 'Nicio fermă nu a fost salvată pentru utilizare offline.',
  'NoOngoingVisit': 'Va rog faceţi clik pe (+) pentru a incepe o vizita nouă',
  'NoPrecipitation': 'Fără precipitații (<5 mm/săptămână)',
  'NoSampleDateSelectionWarning': 'Nu a fost selectată nicio dată de prelevare. Vă rugăm să selectați cel puțin unul pentru a evita un raport gol.',
  'NoTelepacAvailable': 'Telepac nu este disponibil',
  'NoVariety': 'Fără Varietate',
  'NoWind': 'Briză sau fără vânt (<17 m/s)',
  'NonCompensableLosses': 'Pierderi necompensabile',
  'NonIrrigated': 'Neirigat',
  'NonOrganic': 'Non organic',
  'NormalSoilMoisture': 'Ca de obicei',
  'NormalTemperature': 'Fara risc de inghet/caldura (2°C',
  'NormalVegetation': 'Sănătatea normală a vegetației',
  'NotAffected': 'Neafectat',
  'NotAvailable': 'Nu este disponibil',
  'NumberMatchingFields': 'Număr de parcele concordante',
  'NumberMatchingSamples': 'Număr de eșantioane concordante',
  'NumberOfFields': 'Număr de parcele',
  'NumberOfGrainsPerRow': 'Numărul de boabe pe rând',
  'NumberOfLossAdjusters': 'Număr de inspectori',
  'NumberOfPolicies': 'Număr de contracte',
  'NumberOfRowsPerHead': 'Numărul de rânduri pe cap',
  'NumberOfSamples': 'Număr de eșantioane',
  'Occurence': 'Freqventa',
  'OfFieldArea': 'a zonei de câmp',
  'OfficialHarvestArea': 'Zona oficială de recoltare',
  'Offline': 'Offline',
  'OfflineAreas': 'Zone offline',
  'OfflineFarms': 'Ferme offline',
  'Ok': 'Ok',
  'Ongoing': 'In curs',
  'OpenCamera': 'Camera deschisa',
  'OpenFarm': 'Accesați ferma',
  'OpenPolicy': 'Deschideți contractul',
  'OpenVisit': 'Vizită deschisă',
  'Or': 'Sau',
  'Organic': 'Ecologic',
  'OrganicQuestionMark': 'Organic?',
  'OtherCompensableLosses': 'Alte pierderi compensabile',
  'OtherFields': 'Alte câmpuri',
  'OverrideCalculation': 'Ignorați randamentul agregat',
  'OverrideEstimatedYield': 'Ignorați randamentul estimat',
  'Overview': 'Prezentare generală',
  'PER': 'Peru',
  'POL': 'Polonia',
  'PRY': 'Paraguay',
  'Password': 'Parolă',
  'PasswordMissing': 'Vă rugăm să introduceți parola',
  'PastMonth': 'Luna trecută',
  'PastReportsClickToRefresh': 'Rapoarte precedent - daţi clic pentru actualizare',
  'PastWeek': 'Săptămâna trecută',
  'PastYear': 'Anul trecut',
  'Pause': 'Pauză',
  'PeakVegetationDate': 'Data vârfului de vegetație',
  'PendingDownload': 'Descărcare în așteptare',
  'PendingUpload': 'În așteptarea încărcării',
  'PerCropSignature': 'Semnătură per crop',
  'Phone': 'Telefon',
  'Photo': 'Fotografie',
  'Photos': 'Fotografii',
  'PhotosAndReferences': 'Fotografii și referințe',
  'PixelResolution': 'Rezoluția pixelilor',
  'PlanetTiles': 'Planet Tiles',
  'PlanetTilesBoundsWarning': 'Nu sunt disponibile plăci pentru data și zona selectate. Vă rugăm să selectați o altă dată sau zonă.',
  'PlantDensity': 'Densitatea plantei',
  'PlantsCollected': 'Plante colectate',
  'PleaseAddFields': 'Vă rugăm să adăugați câteva parcele pentru a continua',
  'PleaseConnect': 'Nu a fost detectată nicio conexiune la internet. Pentru a vă autentifica, asigurați-vă că sunteți conectat la internet!',
  'PleaseEnableLocation': 'Activați locația pentru GreenTriangle.',
  'PleaseEnablePhotoPermissions': 'Pentru a continua, vă rugăm să activați camera în setările dvs',
  'PleaseEnterEmail': 'Introduceți un e-mail.',
  'PleaseEnterValue': 'Introduceți o valoare.',
  'PleaseReviewReportBeforeSigning': 'Vă rugăm să examinați raportul înainte de a semna',
  'PleaseSelectClaim': 'Vă rugăm să selectați revendicare',
  'PleaseSelectDate': 'Vă rugăm să selectați o dată.',
  'PleaseSelectFarm': 'Selectați o fermă pentru a continua',
  'PleaseSelectField': 'Selectați o parcela pentru a continua',
  'PleaseSelectLocation': 'Selectați o locație pentru a continua.',
  'PleaseSelectPolicy': 'Selectați un contract pentru a continua',
  'PleaseUpgradeLatest': 'Versiunea dvs. de GreenTriangle nu este actualizată. Vă rugăm să actualizați la cea mai recentă versiune',
  'PointOrZone': 'Punct/Zona',
  'Policies': 'Contracte',
  'Policy': 'Contract',
  'PolicyFormComment': 'Informații suplimentare despre politică',
  'PolicyNumber': 'Număr contract',
  'Polygon': 'Poligon',
  'Portfolio': 'Portofoliu',
  'PortfolioReport': 'Raport portofoliu',
  'PotentialDuplicates': 'Următoarele intrări arată similar. Luați în considerare selectarea uneia dintre următoarele opțiuni',
  'Practice': 'Practică',
  'PrecipitationInfo': 'Precipitațiile săptămânale totale indică apa lichidă și înghețată acumulată, inclusiv ploaia și zăpada, care cade la suprafața Pământului în termen de 7 zile. Nu include ceața, roua sau precipitațiile care se evaporă în atmosferă înainte de a ateriza la suprafața Pământului.',
  'Preview': 'Previzualizare',
  'PreviewAndSend': 'Previzualizare si trimite',
  'Previous': 'Anterior',
  'PreviousDate-s': 'Data anterioară',
  'PriceRetainedByExpert': 'Pret retinut de expert',
  'Primary': 'Primar',
  'PrimaryYieldLoss': 'Pierderea randamentului primar',
  'PrivacyPolicy': 'Politica de confidențialitate',
  'Processing': 'Prelucrare',
  'Productivity': 'Productivitate',
  'ProductivityInfo': 'Scorul de productivitate estimează cât de productiv a fost câmpul din punct de vedere istoric',
  'ROU': 'România',
  'RUS': 'Rusia',
  'RainstormInfo': 'Precipitații maxime pe oră în această săptămână.',
  'RainstormLevel1': 'Ploaie Nivel 1 (<20 mm/h)',
  'RainstormLevel2': 'Ploaie Nivelul 2 (20-30 mm/h)',
  'RainstormLevel3': 'Ploaie Nivel 3 (30-40 mm/h)',
  'RainstormLevel4': 'Ploaie Nivel 4 (40-50 mm/h)',
  'RainstormLevel5': 'Ploaie Nivel 5 (>50 mm/h)',
  'Recipients': 'Destinatari',
  'RecognizedHazard': 'Risc recunoscut',
  'Reference': 'Referință',
  'Refresh': 'Reîmprospăta',
  'Region': 'Regiune',
  'RegionLevel': 'La nivel de regiune',
  'RegionLevel_commune': 'Comuna',
  'RegionLevel_country': 'Țară',
  'RegionLevel_state': 'Stat',
  'RegionalStatistic': 'Statistică regională',
  'Regions': 'Regiuni',
  'RelativeScoring': 'Scor relativ',
  'RelativeScoringInfo': 'Scorurile relative reflectă diferența dintre scorul unui câmp și scorul median al câmpurilor unei regiuni (folosind aproximativ 100 de câmpuri eșantion), în timp ce scorul %-ile poziționează scorul câmpului în câmpurile regiunii (5 fiind percentila superioară, 1 cea de jos). percentila, 3 mediana)',
  'Remove': 'Elimina',
  'RemoveCrop': 'Eliminați cultură',
  'RemoveFarm': 'Eliminați ferma',
  'RemoveField': 'Eliminați parcela',
  'RemoveHarvest': 'Îndepărtați recolta',
  'RemoveLoss': 'Eliminați pierderea',
  'RemovedGroups': 'Grupuri eliminate',
  'RenewHarvests': 'Reînnoiți recolta',
  'ReportDate': 'Data raportului',
  'ReportDoesntExist': 'Raportul nu există',
  'ReportGenerationPageComment': 'Comentariu general asupra raportului',
  'ReportNoFields': 'Această fermă nu are parcele pentru a crea un raport',
  'Reset': 'Resetare',
  'ResetLink': 'Trimiteți un link de resetare',
  'Restore': 'Restabili',
  'Resume': 'Reluați',
  'Risks': 'Riscuri',
  'RowDistanceCollected': 'Distanța rândului colectată',
  'RowsAndWidth': 'Rânduri și lățime',
  'SRB': 'Serbia',
  'Sample': 'Eșantion',
  'Sample-s': 'Mostre(e)',
  'SampleCropTitle': 'Număr de eșantioane pe cultură',
  'SampleDate': 'Data eșantionului',
  'SampleFormComment': 'Comentariu / Observație',
  'SampleFormMissingImagesWarning': 'Nu ați făcut o fotografie pentru această mostră. Vă rugăm să adăugați unul pentru a continua.',
  'SampleLocation': 'Locația eșantionului',
  'SampleLocationInaccurate': 'Locație inexactă - Vă rugăm să activați Locația precisă, dacă nu deja. Apoi faceți clic din nou pe „Folosește locația mea”.',
  'SampleLocationOutsideField': 'Locația eșantionului nu se află în câmpul selectat.',
  'SampleLocationPrompt': 'Selectați parcela respectivă pe hartă, apoi apăsați lung pe hartă pentru a seta locația eșantionului sau apăsați butonul din stânga pentru a utiliza locația curentă.',
  'SampleType': 'Tipul eșantionului',
  'SampledArea': 'Zona eșantionată',
  'SampledBy': 'Eșantionat de',
  'Samples': 'Eșantioane',
  'SamplingDate-s': 'Data (datele) de eșantionare',
  'Save': 'Salvați',
  'SaveOffline': 'Salvați offline',
  'Saving': 'Salvare în curs...',
  'Score': 'Scor',
  'Search': 'Căutați',
  'SearchAddTipLabel': 'Termenii de cӑutare de combinӑ',
  'SearchExampleDescAddedBy': 'Tastați o adresă de e-mail pentru a afișa eșantioanele adăugate de acel utilizator, precum și eșantioanele respectivei parcele și ferme',
  'SearchExampleDescAddress': 'Tastați o adresă pentru a recentra harta în locația respectivă.',
  'SearchExampleDescCropCondition': 'Tastați o stare a culturii pentru a evidenția eșantioanele corespunzatoare.',
  'SearchExampleDescFarm': 'Găsiți o fermă și parcele și eșantioanele sale, după nume sau adresă',
  'SearchExampleDescHarvest': 'Găsiți toate articolele referitoare la o campanie specifică introducând un tip de cultură, anotimp sau an. Dacă introduceți un tip de cultură, rezultatele vor fi limitate la acest an. Puteți căuta ani de recoltare mai vechi într-o a doua căutare.',
  'SearchExampleDescPolicy': 'Găsiți toate articolele referitoare la un număr de contract',
  'SearchExampleLabelAddedBy': 'găsiți eșantioane adăugate de o adresă de e-mail',
  'SearchExampleLabelAddress': 'oraș, adresă sau țară',
  'SearchExampleLabelCropCondition': 'găsiți eșantioane pentru o anumită stare a unei culturi (de exemplu, „slabă”)',
  'SearchExampleLabelFarm': 'Căutare după numele și adresa fermei',
  'SearchExampleLabelHarvest': 'căutare după campania, de ex. „grâu”',
  'SearchExampleLabelPolicy': 'Căutare după numărul contractului',
  'SearchExampleLabelUserGroup': 'Găsiți articole în anumite portofolii',
  'SearchForClaimNumber': 'Căutați numărul revendicării',
  'SearchVisit': 'Găsiți o vizită nealocată',
  'Secondary': 'Secundar',
  'SecondaryYieldLoss': 'Pierderea randamentului secundar',
  'SeedWeightPerHead': 'Greutatea semințelor pe cap',
  'Select': 'Selectați',
  'SelectAll': 'Selectați tot',
  'SelectAppellation': 'Selectați denumirea',
  'SelectCostType': 'Selectați tipul de cost',
  'SelectCropCondition': 'Selectați starea culturii',
  'SelectCropType': 'Selectați tipul de cultură',
  'SelectCurHarvest': 'Selectați cultura curentă',
  'SelectDate': 'Selectați Data',
  'SelectFarm': 'Selectați o fermă',
  'SelectFarmsForOfflineAccess': 'Selectați ferme pentru acces offline',
  'SelectField': 'Selectați o parcela',
  'SelectFieldCurHarvest': 'Selectați recolta curentă a parcelei',
  'SelectFieldCurPolicy': 'Selectați contractul actual al parcelei',
  'SelectHarvest': 'Selectați campania',
  'SelectHarvestYear': 'Selectați anul recoltei',
  'SelectLossCause': 'Selectați cauza pierderii',
  'SelectOneGroup': 'Vă rugăm să selectați articole dintr-un singur portofoliu',
  'SelectPac': 'Selectați un fișier valid (.ZIP, .XML, .KML, GeoJSON). Dacă sunteți sigur că acest fișier este corect, vă rugăm să ne contactați la support@green-triangle.com.',
  'SelectPolicy': 'Selectați un contract',
  'SelectPortfolio': 'Selectați portofoliu',
  'SelectReportType': 'Selectați tipul raportului',
  'SelectSampleType': 'Selectați Tipul eșantionului',
  'SelectSubplotId': 'Selectați ID subplot',
  'SelectVariety': 'Selectați Varietate',
  'SelectVegetationStage': 'Selectați fenofaza de vegetație (BBCH)',
  'SelectVisitType': 'Selectaţi tip de vizită',
  'SelectWillOverwriteShape': 'Sigur doriți să selectați aceast perimetru? Perimetrul actual va fi suprascris.',
  'SelectYear': 'Selectați anul',
  'SelectedAppellation': 'Denumire aleasă',
  'SelectedCrops': 'Culturi alese',
  'SelectedField': 'Câmp selectat',
  'Send': 'Trimiteți',
  'SendFeedback': 'Trimiteți observații',
  'SendTo': 'Trimite catre',
  'Sending': 'Se trimite',
  'Sent': 'Trimis',
  'Settings': 'Setări',
  'SevereHailRisk': 'Risc sever de daune din cauza grindinei',
  'SeverePrecipitation': 'Precipitații severe (75mm+/săp)',
  'SevereWind': 'Uragan (>32.5 m/s)',
  'ShapeTracerAddPoint': 'Apăsați pe hartă pentru a adăuga un punct sau apăsați pe un punct pentru a-i schimba poziția sau pentru a-l șterge.',
  'ShapeTracerEditPoint': 'Mutați punctul cu ajutorul semnului albastru sau apăsați oriunde altundeva pentru a reveni la adăugarea de puncte.',
  'Share': 'Partajați',
  'ShowAll': 'Arata tot',
  'ShowDataOnMap': 'Afișare date pe hartă',
  'ShowLastAdded': 'Afișează ultimul adăugat',
  'ShowOnMap': 'Afișați pe hartă',
  'ShowSamplesAddedIn': 'Afișați mostrele adăugate în ultimele:',
  'SignAndClose': 'Semnează și închide',
  'SignatoryName': 'Numele semnatarului',
  'SignatoryNames': 'Numele semnatarilor',
  'Signature': 'Semnătură',
  'Signatures': 'Semnături',
  'SnowIce': 'Zăpadă/Gheață',
  'SoilMoistureInfo': 'Indicele de anomalie a umidității solului cuantifică starea de umiditate la adâncimi de 40 cm în sol pentru o perioadă de 10 zile, față de media pe termen lung (10 ani) din aceeași perioadă.',
  'SomethingWentWrongError': 'Ceva n-a mers bine.',
  'SowingDate': 'Data semănatului',
  'SowingDensity': 'Densitatea de samӑnat',
  'SpaceBetweenPlants': 'Spațiul dintre plante',
  'SpaceBetweenRows': 'Spațiu între rânduri',
  'SquareArea': 'Zona Pieței',
  'SquareMeterCut': 'Tăiere în metru pătrat / Densitatea plantelor',
  'SquareSide': 'Latura pătratului',
  'SquareSize': 'Dimensiunea pătratului',
  'Stability': 'Stabilitate',
  'StabilityInfo': 'Scorul de stabilitate măsoară cât de stabilă a fost productivitatea dedusă a unui câmp în timp',
  'StartDate': 'Data de început',
  'StartingYield': 'Randament fezabil',
  'Status': 'stare',
  'SubjectToValidationOfReseedingByExpert': 'Sub rezerva validării re-însămânțării de către expert',
  'Subplots': 'Subploturi',
  'Summary': 'Rezumat',
  'SupportedSurface': 'Suprafata sustinuta',
  'SureAddSampleWithoutField': 'Sigur doriți să adăugați un eșantion fără câmp?',
  'SureCancel': 'Sigur doriți să anulați înainte de a salva datele?',
  'SureCloseVisit': 'Sigur doriți să închideți vizita? Raportul nu a fost semnat.',
  'SureCloseVisitPartial': 'Sigur doriți să închideți vizita? Raportul a fost doar parțial semnat.',
  'SureDeleteFarm': 'Sigur doriți să ștergeți această fermă?',
  'SureDeleteField': 'Sigur doriți să ștergeți acestă parcela?',
  'SureDeleteHarvest': 'Sigur doriți să ștergeți acestă campanie?',
  'SureDeleteSample': 'Sigur doriți să ștergeți acest eșantion?',
  'SureDeleteVisit': 'Sigur doriți să ștergeți această vizită?',
  'SureReset': 'Sigur vrei să resetați? Veți pierde toate modificările offline.',
  'SureSelectObject': 'Doriți să selectați în schimb următoarele?',
  'SureSignOut': 'Sigur doriți să vă deconectați? Veți pierde toate modificările făcute offline.',
  'SurfacePAC': 'PAC de suprafață',
  'SurfaceTemperatureInfo': 'Acest strat afișează  temperaturile minime și maxime săptămânale la suprafeței solului indicând riscul de îngheț și valuri de căldură.',
  'Syncing': 'Sincronizare',
  'TableNoRowsFound': 'Nu s-au găsit rânduri',
  'TableOf': 'din',
  'TablePage': 'Pagină',
  'TableRows': 'Rând',
  'TelepacAvailableFor': 'Telepac încărcat pentru',
  'TelepacNr': 'Numărul Telepac',
  'TelepacReport': 'Import PAC',
  'TemperatureInfo': 'Acest strat afișează temperaturi minime și maxime săptămânale pentru a detecta riscul de îngheț și valurile de căldură.',
  'Tertiary': 'Terţiar',
  'TertiaryYieldLoss': 'Pierderea randamentului terțiar',
  'ThousandKernelWeightGrams': 'Greutatea la 1000 de boabe (g)',
  'TopNCropsByHarvestArea': 'Culturi de top în funcție de zona de recoltare',
  'Total': 'Total',
  'TotalArea': 'Suprafața totală',
  'TotalCultivatedArea': 'Suprafața cultivată (total)',
  'TotalEarsDetected': 'Total de urechi detectate',
  'TotalHarvestArea': 'Suprafața totală de recoltare',
  'TotalLoss': 'Pierdere totală',
  'TotalYieldPctLoss': 'Pierderea totală a recoltei (%)',
  'UKR': 'Ucraina',
  'URY': 'Uruguay',
  'USA': 'Statele Unite ale Americii',
  'UnableToUpload': 'Nu se poate încărca',
  'UnassignMyself': 'Anulează-mă',
  'Uncategorized': 'Necategorizat',
  'Undo': 'Anulați',
  'UnguaranteedLosses': 'Pierderi negarantate',
  'Unit': 'Unitate',
  'UnitsPerPlant': 'Unități pe plantă',
  'UnknownErrorOccurred': 'A survenit o eroare necunoscută',
  'UpcomingInspection': 'Inspecție viitoare',
  'Update': 'Actualizare',
  'UpdateMemberships': 'Actualizați abonamentele',
  'UploadPACFile': 'Încărcați fișierul PAC',
  'Usage': 'Folosinţӑ',
  'UseMyLocation': 'Folosește locația mea',
  'User': 'Utilizator',
  'UserAdminNoAccess': 'Nu există nicio suprapunere între clienții utilizatori selectați și drepturile dvs. de utilizator-administrator.',
  'UserAdminNoCommon': 'Utilizatorii selectați nu au o companie comună.',
  'UserAdministration': 'Administrarea utilizatorilor',
  'UserGroup': 'Portofoliu',
  'Validation': 'Validare',
  'VegetationInfo': 'Anomalia sănătății vegetației oferă o măsură alternativă a sănătății relative a vegetației comparativ cu anii precedenți (din 2001). Acest indicator poate fi utilizat pentru a monitoriza zonele în care vegetația poate fi stresată, ca un indicator pentru a detecta seceta potențială.',
  'VegetationStage': 'Fenofaza de vegetație',
  'VeryHighPrecipitation': 'Precipitații foarte mari (50-75 mm/săptămână)',
  'VeryLowPrecipitation': 'Precipitații foarte scăzute (5-10 mm/săptămână)',
  'ViewThisInstead': 'Doriți să ieșiți din acest formular și să deschideți următoarele:',
  'VinificationRatio': 'Raport de vinificare (kg/hL)',
  'Visit': 'Vizita',
  'VisitAssign': 'Atribuiți o vizită',
  'VisitCandidateStatus_accepted': 'Admis',
  'VisitCandidateStatus_assigned': 'Alocat',
  'VisitCandidateStatus_rejected': 'Respins',
  'VisitList': 'List de vizite',
  'VisitMode': 'Mod vizite',
  'VisitReport': 'Raport de vizită',
  'VisitReportEmailInfoMsg': 'Acest lucru va trimite raportul de vizită semnat la adresele de e-mail selectate.',
  'VisitType': 'Tip de vizita',
  'VisitValidation': 'Vizitați Validare',
  'VisitYear': 'Vizitați Anul',
  'Visited': 'Vizitat',
  'Visits': 'Vizite',
  'Volatility': 'Volatilitate',
  'WebApp': 'Aplicație web',
  'WeightPerPlant': 'Greutate medie pe plantă',
  'WeightPerUnit': 'Greutate pe unitate',
  'WeightedAverage': 'Medie ponderată',
  'WindInfo': 'Stratul de rafală maximă de vânt săptămânală   reprezintă rafala maximă de vânt înregistrată în ultimele 7 zile la o înălțime de zece metri deasupra suprafeței Pământului.',
  'Withdrawal': 'Retragere',
  'WouldLikeToRestore': 'Am identificat date pentru acest formular care nu au fost salvate din cauza unui blocaj sau a unei actualizări. Doriți să le recuperați ?',
  'Year': 'An',
  'YearEg': 'An (ex. 2019)',
  'Yes': 'Da',
  'YieldAndLoss': 'Pierdere și randament',
  'YieldCalcUnsupportedCrop': 'Calculatorul nu este acceptat pentru acest tip de decupare. În prezent, este posibilă doar atribuirea densității plantelor.',
  'YieldEstimationMethod': 'Metoda de estimare a randamentului',
  'YieldLoss': 'Pierdera de producţie',
  'YieldLossInputHidden': 'Intrarea pierderii randamentului este dezactivată, deoarece cauza pierderii nu este recunoscută.',
  'YieldRatio': 'Raportul randamentului',
  'YieldTooLarge': 'Valoarea randamentul este prea mare',
  'YouMayTryAgain': 'Puteți încerca din nou',
  'ZAF': 'Africa de Sud',
  'ZoomInViewLayer': 'Pentru a vedea acest strat, mariți zoomul',
  'ZoomOutViewLayer': 'Pentru a vedea acest strat, micșorați zoomul',
  'additional-costs': 'Cheltuieli suplimentare',
  'agroforestry': 'Agrosilvicultura',
  'alfalfa': 'Lucernӑ',
  'allSynced': 'Totul a fost sincronizat cu baza de date',
  'almond': 'Migdale',
  'ananas': 'Ananas',
  'angelica': 'Angelica',
  'anise': 'Anason',
  'apples': 'Mere',
  'apples-cider': 'Mere (cidru)',
  'apples-pre-prod': 'Mere (5 ani sau mai puțin)',
  'apricots': 'Caise',
  'apricots-pre-prod': 'Caise (5 ani sau mai puțin)',
  'aromatic-plants': 'Aromatice',
  'artichoke': 'Anghinare',
  'asparagus': 'Sparanghel',
  'avocado': 'Avocado',
  'bananas': 'Banane',
  'barley': 'Orz',
  'barley-6-row': 'orz cu 6 rânduri',
  'barley-malting': 'Orzoaică',
  'barley-seeds': 'Orz seminţe hibrid',
  'base': 'Bază',
  'basic': 'De bază',
  'basil': 'Busuioc',
  'bbch-00-grapes': 'A. Mugurel de iarnă',
  'bbch-05-grapes': 'B. Mugurel lânos',
  'bbch-09-grapes': 'C. Budbreak (vârf verde)',
  'bbch-11-grapes': 'D. Răsărirea frunzelor',
  'bbch-13-grapes': 'E. Frunzele separate',
  'bbch-53-grapes': 'F. Inflorescențe vizibile',
  'bbch-55-grapes': 'G. Inflorescențe separate',
  'bbch-57-grapes': 'H. Flori separate',
  'bbch-64-grapes': 'I. Bloom',
  'bbch-71-grapes': 'J. Set de fructe',
  'bbch-75-grapes': 'K. Boabele de mărimea mazăre',
  'bbch-77-grapes': 'L. Boabele care se ating',
  'bbch-81-grapes': 'M. Veraison',
  'bbch-89-grapes': 'N. Maturitatea',
  'bbch-93-grapes': 'O. Maturarea trestiei/Căderea frunzelor',
  'beans': 'Fasole',
  'beans-dry': 'Fasole uscata',
  'beans-green': 'Fasole verde',
  'beans-red': 'Fasole roşu',
  'beans-seeds': 'Fasole (seminte)',
  'beans-white': 'Fasole alba',
  'beetroot': 'Sfeclӑ',
  'benchmark-yield': 'Randament de referință',
  'black-medick': 'Medic negru',
  'blackberry': 'Mure',
  'blackcurrant': 'Coacӑzӑ negru',
  'blueberry': 'Afine',
  'broccoli': 'Broccoli',
  'bromes': 'Bromes',
  'buckwheat': 'Hrișcă',
  'burdock': 'Brusture',
  'cabbages': 'Vârzӑ',
  'cabbages-feed': 'Vârzӑ (alimentar)',
  'cabbages-red': 'varza rosie',
  'camelina': 'Camelina',
  'caraway': 'Chimen',
  'cardoon': 'Cardon',
  'carob': 'Roşcovă',
  'carrots': 'Morcovi',
  'carrots-feed': 'Morcovi',
  'carrots-young': 'Morcovi',
  'cassava': 'Manioc',
  'castor-bean': 'Fasole de ricin',
  'castration-costs': 'Costuri de castrare ($)',
  'cauliflowers': 'Conopidă',
  'cedrate': 'Cedrate',
  'celeri-leaves': 'țelină (frunze)',
  'celeri-root': 'Rădăcină de țelină)',
  'celery': 'Ţelinӑ',
  'cereals': 'Cereale',
  'chamomile': 'Muşeţel',
  'chard': 'Chard',
  'cherries': 'Cireșe',
  'chervil': 'Asmăţui',
  'chestnut': 'Castan',
  'chia': 'Chia',
  'chickpeas': 'Năut',
  'chicory': 'Cicoare',
  'chilli': 'Ardei iute',
  'chives': 'Arpagic',
  'citrus': 'Citrice',
  'claim-all': 'Închiderea finală a misiunii',
  'claim-delivery': 'Închiderea finală a rezultatelor recoltei',
  'claim-some': 'Închiderea definitivă a culturii',
  'claims-visit': 'Evaluare finală',
  'claims-visit-file': 'estima',
  'clementines': 'Clementine',
  'clover': 'Trifoi',
  'cocoa': 'Cacao',
  'coffea': 'cafea',
  'cold': 'Frig',
  'compensation-costs': 'Cheltuieli de compensare',
  'connected': 'Aplicația este conectată la internet!',
  'coriander': 'Coriandru',
  'corn': 'Porumb',
  'corn-grain': 'Porumb (boabe)',
  'corn-popcorn': 'Porumb (popcorn)',
  'corn-seeds': 'Porumb (seminte)',
  'corn-seeds-fertile': 'Porumb (seminţe fertile)',
  'corn-seeds-sterile': 'Porumb (seminţe sterile)',
  'corn-silage': 'Porumb (siloz)',
  'corn-sweet': 'Porumb dulce',
  'corn-sweet-seeds-fertile': 'Porumb dulce (seminţe fertile)',
  'corn-sweet-seeds-sterile': 'Porumb dulce (seminţe sterile)',
  'corn-waxy': 'Porumb (ceros)',
  'cornsalad': 'Salata de porumb',
  'cotton': 'Bumbac',
  'cover-crop': 'Cultură de acoperire',
  'cranberry': 'Merișor',
  'cress': 'Creson',
  'crop-mon': 'Monitorizarea culturilor',
  'crop_condition': 'Starea culturii',
  'crosne': 'Crosne',
  'cucumbers': 'Castraveţi',
  'cumin': 'Chimion',
  'cynara': 'Cynara',
  'dactylis': 'Dactilis',
  'damson': 'Damson',
  'decree': 'Decret oficial',
  'development': '4. Dezvoltarea părților recoltabile ale plantelor',
  'dill': 'Mărar',
  'disease': 'Boală',
  'downpour': 'Ploaie torenţială',
  'drought': 'Secetă',
  'eggplant': 'Vinete',
  'elder': 'Mai mare',
  'endives': 'Andivӑ',
  'excess-water': 'Exces de apă',
  'excessive-air-humidity': 'Exces de umiditatea (aer)',
  'expected-loss': 'Pierderea așteptată',
  'fallow-land-perennial': 'Pârghie (peren)',
  'fallow-land-spring': 'Pӑmânt nelucrat (primavarӑ)',
  'false': 'Nu',
  'fennel': 'Fenicul',
  'fenugreek': 'schinduf',
  'festuca': 'Festuca',
  'field-beans': 'Fasole',
  'field-beans-seeds': 'Fasole de câmp (semințe)',
  'field-crops': 'Culturi de câmp',
  'field-mustard': 'Muştar de câmp',
  'figs': 'Smochine',
  'fire': 'Foc',
  'first-crop': 'Prima cultură',
  'flageolet-bean': 'Flageoleţi',
  'flood': 'Inundații',
  'flood-risk': 'Riscul de inundații',
  'flowering': '6. Înflorire',
  'fodder-legumes': 'Legume furajer',
  'forest': 'pădure',
  'foxtail-millet': 'Mei coada vulpei',
  'frost': 'Îngheț',
  'fruit-development': '7. Dezvoltarea fructelor',
  'full': 'Acces complet',
  'garlic': 'Ceapӑ',
  'gentiana': 'Gentiana',
  'germination': '0. Germinarea/dezvoltarea mugurilor',
  'gherkins': 'Castraveţiori',
  'ginger': 'Ghimbir',
  'go': 'Activați',
  'good': 'Bun',
  'gooseberries': 'Agrişӑ',
  'gourd': 'Tărtăcuţă',
  'grapefruit': 'Grapefruit',
  'grapes': 'Vie',
  'grapes-food': 'Struguri de masa',
  'grapes-juice': 'Suc de struguri',
  'grapes-must': 'Must de struguri',
  'grapes-nursery': 'Pepiniera de vita de vie',
  'grapes-table': 'Strugurii de masă',
  'grassland': 'Pajiști',
  'grassland-perennial': 'Pajiști (perene)',
  'green-peas': 'Mazӑre',
  'group-allocator': 'Alocator de grup',
  'hail': 'Grindină',
  'harvest': 'Recoltat',
  'harvest_crop': 'Tip cultură',
  'harvested': 'Recoltat',
  'harvesting-costs': 'Costuri de recoltare ($)',
  'hazelnuts': 'Alune',
  'heat-wave': 'Val de căldură',
  'heatstroke': 'Exes de căldură',
  'heavy-rainfall': 'Precipitații abundente',
  'hemp': 'Cânepă',
  'hemp-paper': 'Cânepӑ (fibre)',
  'hemp-seeds': 'Cânepӑ (seminţe)',
  'high-res-mapbox': 'Rezoluție înaltă',
  'historical-yield': 'Raportul istoric al randamentului',
  'hop': 'Hamei',
  'hyssop': 'Isop',
  'inflorescence': '5. Apariția inflorescenței / spicului',
  'interfield': 'Compararea parcele',
  'interfield-a': 'Nu există vegetație',
  'interfield-b': 'Vegetație rară',
  'interfield-c': 'Vegetație scăzută',
  'interfield-d': 'Vegetație moderată',
  'interfield-e': 'Vegetație moderată până la densă',
  'interfield-f': 'Vegetație densă',
  'interfield-g': 'Activitate vegetală mare',
  'interfield-h': 'Activitate vegetală foarte mare',
  'interfield-i': 'Cea mai mare activitate vegetală',
  'interyield': 'Predicția randamentului',
  'interyield-a': 'Randament extrem de scăzut',
  'interyield-b': 'Randament foarte scăzut',
  'interyield-c': 'Randament scăzut',
  'interyield-d': 'Randament scăzut la moderat',
  'interyield-e': 'Randament moderat',
  'interyield-f': 'Randament moderat la ridicat',
  'interyield-g': 'Randament ridicat',
  'interyield-h': 'Randament foarte ridicat',
  'interyield-i': 'Randament maxim',
  'intrafield': 'Variabilitate între parcele',
  'itemsToUpload': 'Sunt informații de încărcat în baza de date.',
  'jerusalem-artichoke': 'Anghinare de la Ierusalim',
  'kale': 'Kale',
  'kiwi': 'Kiwi',
  'lack-of-sunlight': 'Lipsa de lumina',
  'lavandin': 'Lavandin',
  'lavender': 'Lavandă',
  'leaf-development': '1. Dezvoltarea frunzelor',
  'leek': 'Praz',
  'lemon-balm': 'Balsam de lamaie',
  'lemons': 'Lӑmâie',
  'lentils': 'Linte',
  'lentils-berry': 'Linte verde de padure',
  'lentils-puy': 'Linte verde Puy',
  'lettuce': 'Salată verde',
  'linden': 'Tei',
  'lineseed-textile-seeds': 'Textil din semințe de in (semințe)',
  'linseed': 'In',
  'linseed-oilseed': 'In (ulei)',
  'linseed-textile': 'Textil cu semințe de in',
  'linseed-textile-fiber': 'In (fibrӑ)',
  'linseed-textile-seeds': 'Semințe de in (semințe)',
  'linseed-textile-straw': 'In (pai)',
  'lotus': 'Lotus',
  'lupin': 'Lupin',
  'lupin-sweet': 'Lupin dulce',
  'mandarins': 'Mandarine',
  'mangos': 'Mango',
  'meadow-grass': 'iarbă de luncă',
  'medicinal-plants': 'Medicinale',
  'melilotus': 'Melilotus',
  'melons': 'Pepeni',
  'membership_type': 'Tipul de membru',
  'meslin': 'Meslin',
  'millet': 'Mei',
  'mint': 'Mentă',
  'mirabelles': 'Mirabele (prune)',
  'miscanthus': 'Iarba de argint',
  'mixed-cereal': 'Amestec de paioase',
  'monitoring-visit': 'Monitoring',
  'monitoring-visit-file': 'monitorizarea',
  'monitoring-with-review': 'Aprovizionare/urmărire (cu management, fără aprovizionare)',
  'monitoring-with-review-provisioning': 'Aprovizionare/urmărire (cu management, cu aprovizionare)',
  'monitoring-without-review': 'Aprovizionare/monitorizare (fara management, fara aprovizionare)',
  'monitoring-without-review-provisioning': 'Aprovizionare/monitorizare (fără management, cu aprovizionare)',
  'month_0': 'Ian',
  'month_1': 'Feb',
  'month_10': 'Noi',
  'month_11': 'Dec',
  'month_2': 'Mar',
  'month_3': 'Apr',
  'month_4': 'Mai',
  'month_5': 'Iun',
  'month_6': 'Iul',
  'month_7': 'Aug',
  'month_8': 'Sept',
  'month_9': 'Oct',
  'most-recent': 'Cele mai recente',
  'mustard': 'Muștar',
  'nashi-pear': 'Pere nashi',
  'nectarines': 'Nectarine',
  'noOfflineAreas': 'Pentru a utiliza GreenTriangle offline, trebuie să descărcați hărțile pe dispozitivul dvs. Apăsați Da pentru a descărca acum.',
  'none': 'Niciunul',
  'normal': 'Normal',
  'notConnected': 'Aplicația nu este conectată la internet.',
  'not_available': 'Nu este disponibil',
  'not_planted': 'Neplantat',
  'nursery': 'Pepinieră',
  'oats': 'Ovăz',
  'oats-seeds': 'ovăz (semințe)',
  'oilseeds': 'Seminte oleaginoase',
  'olives': 'Măsline',
  'onions': 'Ceapă',
  'oranges': 'Portocale',
  'orchard': 'Livadă (mai mult de 5 ani)',
  'orchard-pre-prod': 'Livadă (5 ani sau mai puțin)',
  'orchards': 'Livezile',
  'oregano': 'Oregano',
  'other-aromatic-medicinal': 'Altele plante aromatice sau medicinale',
  'other-berries': 'Alte boabe',
  'other-cereals': 'Alte cereale',
  'other-feed': 'Alte culturi furajere',
  'other-fruits': 'Alte fructe',
  'other-industry': 'Alte culturi industriale',
  'other-legumes': 'Alte leguminoase',
  'other-loss': 'Altele (pierdere)',
  'other-oilseed': 'Alte produse oleaginoase',
  'other-textile': 'Alte materiale textile',
  'other-vegetables': 'Alte legume',
  'parnsip': 'Părnsip',
  'parsley': 'Pătrunjel',
  'peach-blood': 'Piersică de sânge',
  'peach-flat': 'Piersic plat',
  'peaches': 'Piersici',
  'peaches-pre-prod': 'Piersici (5 ani sau mai puțin)',
  'peanuts': 'Arahide',
  'pears': 'Pere',
  'pears-pre-prod': 'Pere (5 ani sau mai puțin)',
  'peas': 'Mazăre',
  'peas-feed': 'Mazăre (furaj)',
  'peas-seeds': 'Mazӑre (seminţe)',
  'peas-split': 'Mazăre despicată',
  'pepper': 'Ardei',
  'perennial': 'Perene',
  'perennial-crops': 'Culturi perene',
  'persimmon': 'Kaki',
  'pest': 'Dăunători',
  'pickle': 'Castravete murat',
  'pineapples': 'Ananas',
  'pistachio': 'Fistic',
  'pleaseCheckForm': 'Verificați câmpurile formularului și încercați din nou.',
  'plum': 'Prune',
  'polyculture': 'Policultura',
  'poor': 'Prost',
  'poppy': 'Mac',
  'potatoes': 'Cartofi',
  'potatoes-industry': 'Cartofi (procesare)',
  'potatoes-seeds': 'Cartofi (seminte)',
  'potatoes-starch': 'Cartofi (amidon)',
  'potatoes-ware': 'Cartofi de consum',
  'precipitation': 'Precipitații totale',
  'predicted-yield': 'Raportul de randament estimat',
  'preinspection-none': 'Evaluare a riscurilor',
  'preinspection-visit': 'Inspecţie de risc',
  'preinspection-visit-file': 'preinspecție',
  'production-costs': 'Costuri de producție ($)',
  'pulses': 'Pulsuri',
  'pumpkin': 'Dovleac',
  'purification-costs': 'Costuri de purificare ($)',
  'quinces': 'Gutui',
  'quinoa': 'Quinoa',
  'radishes': 'Ridichi',
  'rainstorm': 'Furtună de ploaie',
  'rapeseed': 'Rapiță',
  'rapeseed-feed': 'Rapiţӑ (alimentar)',
  'rapeseed-seeds': 'Rapiţӑ (seminţe hibrid)',
  'raspberry': 'Zmeurӑ',
  'ray-grass': 'iarbă de raze',
  'redcurrant': 'Coacӑzӑ roşu',
  'remaining-crops': 'Culturi rămase',
  'replanting': 'Replantarea',
  'resowing-costs': 'Cheltuieli de reinsemantare',
  'rhubarb': 'Rubarbӑ',
  'rice': 'Orez',
  'ripening': '8. Coacerea sau maturitatea fructelor sau semințelor',
  'rocket': 'Rachetă',
  'root-crops': 'Culturi rădăcinoase',
  'rosemary': 'Rozmarin',
  'rutabaga': 'Rutabaga',
  'rye': 'Secară',
  'rye-seeds': 'Secarӑ seminţe hibrid',
  'safflower': 'Sofranul',
  'sage': 'Salvie',
  'sainfoin': 'Sainfoin',
  'salad': 'Salatӑ',
  'salsify': 'Salsifi',
  'salvia': 'Salvie',
  'sandstorm': 'Furtună de nisip',
  'satellite': 'Satelit',
  'satureja': 'Satureja',
  'second-crop': 'A doua cultură',
  'senescence': '9. Senescență, începutul stării latente',
  'shallots': 'Eşalot',
  'side-shoot': '2. Formarea lăstarilor laterali/înfrățire',
  'snow': 'Zăpadă',
  'snow-peas': 'Mazӑre de zӑpadӑ',
  'soil-moisture': 'Umiditatea solului',
  'sorghum': 'Sorg (boabe)',
  'sorghum-feed': 'Sorg (siloz)',
  'sorghum-seeds': 'Sorg (seminţe)',
  'sorghum-silage': 'Sorg (siloz)',
  'sorrel': 'Măcriș',
  'soybeans': 'Soia',
  'soybeans-seeds': 'soia (semințe)',
  'spelt': 'Spelta',
  'spinach': 'Spanac',
  'spring': 'Primăvară',
  'sprouts': 'Vârzӑ de Bruxelles',
  'squash': 'Dovlecei',
  'squash-max': 'Dovleac (Cucurbita maxima)',
  'static-data': 'Date despre țară',
  'stevia': 'Stevia',
  'storm': 'Furtună',
  'strawberry': 'Capşuni',
  'sugar-beet': 'Sfeclă de zahăr',
  'sugar-beet-feed': 'Sfeclӑ de zahar (alimentar)',
  'sugar-beet-seeds': 'Sfeclӑ de zahar (seminţe)',
  'sugar-cane': 'Trestie de zahăr',
  'sugar-cane-feed': 'Trestie de zahăr (furaj)',
  'sunburn': 'Arsița',
  'sunflower': 'Floarea soarelui',
  'sunflower-oilseed': 'Floare Soarelui (ulei)',
  'sunflower-seeds': 'Floare soarelui (seminte)',
  'surface-temperature': 'Riscuri de îngheț sau de val de căldură (suprafaţa)',
  'switchgrass': 'Switchgrass',
  'syncDataDb': 'Datele se sincronizează cu baza de date.',
  'tarragon': 'Tarhon',
  'temperature': 'Risc de îngheț și căldură',
  'thistle': 'Ciulin',
  'thyme': 'Cimbru',
  'timothy-grass': 'Timothy-iarbă',
  'tobacco': 'Tutun',
  'tobacco-blond': 'Tutun blond',
  'tobacco-brown': 'Tutun brun',
  'tobacco-burley': 'Tutun Burley',
  'tobacco-virginie': 'Tutun Virginia',
  'tomatoes': 'Roșii',
  'tomatoes-processing': 'Roşii (procesare)',
  'tons': 't',
  'tornado': 'Tornadă',
  'triticale': 'Triticale',
  'triticale-seeds': 'Triticale (semințe)',
  'true': 'Da',
  'turmeric': 'Curcumă',
  'turnip': 'Nap',
  'turnip-feed': 'Nap (alimentar)',
  'unit': 'unitate',
  'unknown': 'Necunoscut',
  'urtica': 'Urzici',
  'user-admin': 'Manager de utilizatori',
  'valerian': 'Valeriană',
  'validation-visit': 'Vizită preliminară pentru revendicare',
  'validation-visit-file': 'apariția',
  'validation-with-review': 'Apariția cu conducerea',
  'validation-without-review': 'Apariție fără management',
  'vanilla': 'Vanilie',
  'vegetables': 'Legume',
  'vegetation': 'Anomalie de dezvoltare a vegetației',
  'vegetative-growth': '3. Alungirea tulpinii/creșterea rozetei',
  'verbena': 'verbena',
  'very_good': 'Foarte bun',
  'very_poor': 'Foarte prost',
  'vetch': 'Mӑzӑriche',
  'vin-de-table': 'Vin de masă',
  'walnuts': 'Nuci',
  'watermelon': 'Pepene roşu',
  'weeds': 'Buruieni',
  'wheat': 'Grâu',
  'wheat-einkorn': 'Grâu bulgar',
  'wheat-feed': 'grâu (furaj)',
  'wheat-hard': 'Grâu (durum)',
  'wheat-hard-seeds': 'Durum grӑu (seminţe hibrid)',
  'wheat-seeds': 'Grӑu (seminţe hibrid)',
  'wheat-top': 'Grâu (moale - măcinare îmbunătățită)',
  'wild-animals': 'Animale sălbatice',
  'wind': 'Vânt',
  'winter': 'Iarnă',
  'yams': 'Yams',
  'zucchini': 'Zucchini',
  '-': '-',
  'UnknownLoss': 'Necunoscut (Cauza pierderii)',
  'acres': 'ac',
  'added_by': 'Adăugat de',
  'added_on': 'Adăugat pe',
  'arrobas-bra': '@/ha',
  'bags-per-hectare': 'sc/ha',
  'bales-per-acre': 'Baloţi/ha',
  'bra_agree_with_result': 'Você concorda com o resultado apurado?',
  'bra_average': 'Média (tipo 2)',
  'bra_clay': 'Argiloso (tipo 3)',
  'bra_insured_facilitated': 'O segurado facilitou o processo de amostragem? (Se não, explique nos comentários)',
  'bra_insured_implemented_zarc_window': 'O segurado implantou a cultura dentro da janela de indicação do ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_last_liming_plastering_date': 'Quando foi feita a última calagem e gessagem na área segurada? (descrever a frequência da análise do solo, qual o perfil da camada do solo é trabalhada, tipo de calcário utilizado, faz uso de AP, já utilizou gesso na lavoura, etc)',
  'bra_losses_from_rnc': 'O perito constatou prejuízos por RNC? Explique os percentuais?',
  'bra_manual': 'Manual',
  'bra_mechanized': 'Mecanizada',
  'bra_necessary_to_subdivide': 'Foi necessário a subdivisão dos itens segurados? (Se sim, explique nos comentários)',
  'bra_planned_location': 'A lavoura foi implantada em local previsto? (Se não, explique nos comentários)',
  'bra_recommended_risk_location': 'A cultivar/variedade semeada é recomendada para o local do risco, conforme ZARC? (Se parcialmente sim, por favor, explique nos comentários)',
  'bra_sampling_type': 'Qual o tipo de amostragem utilizada para estimativa de produtividade da lavoura?',
  'bra_sandy': 'Arenoso (tipo 1)',
  'bra_soil_type': 'Tipo de solo',
  'bra_treatments_effective': 'Os tratos culturais foram efetivos no manejo da cultura?',
  'bra_uniform_management': 'O manejo empregado na lavoura segurada é igual das áreas de recurso próprio? (Se não, explique nos comentários)',
  'bra_visual': 'Visual',
  'brl': 'R$',
  'bushels-per-acre': 'bu/ac',
  'centimeters': 'cm',
  'chf': 'Fr.',
  'corn-grain-white': 'Porumb (boabe) (white)',
  'corn-grain-yellow': 'Porumb (boabe) (yellow)',
  'corn-silage-white': 'Porumb (siloz) (white)',
  'corn-silage-yellow': 'Porumb (siloz) (yellow)',
  'crop_id': 'Tip cultură',
  'custom-a': 'Vegetația Mediană a municipiului',
  'decitons-per-hectare': 'qx/ha',
  'development-cereals': '4. Dezvoltarea părților recoltabile ale plantelor',
  'development-sorghum': '4. Dezvoltarea părților recoltabile ale plantelor',
  'development-sugar-beet': '4. Dezvoltarea părților recoltabile ale plantelor',
  'eur': '€',
  'eur-per-hectoliter': '€/hL',
  'eur-per-ton': '€/t',
  'brl-per-bag': 'R$/sc',
  'farm_id': 'Fermă',
  'feet': 'ft',
  'flowering-cereals': '6. Înflorire',
  'flowering-corn': '6. Înflorire',
  'flowering-fruits': '6. Înflorire',
  'flowering-grapes': '6. Înflorire',
  'flowering-legumes': '6. Înflorire',
  'flowering-rapeseed': '6. Înflorire',
  'flowering-sorghum': '6. Înflorire',
  'flowering-soybeans': '6. Înflorire',
  'flowering-sunflower': '6. Înflorire',
  'fruit-development-cereals': '7. Dezvoltarea fructelor',
  'fruit-development-corn': '7. Dezvoltarea fructelor',
  'fruit-development-fruits': '7. Dezvoltarea fructelor',
  'fruit-development-grapes': '7. Dezvoltarea fructelor',
  'fruit-development-legumes': '7. Dezvoltarea fructelor',
  'fruit-development-rapeseed': '7. Dezvoltarea fructelor',
  'fruit-development-sorghum': '7. Dezvoltarea fructelor',
  'fruit-development-soybeans': '7. Dezvoltarea fructelor',
  'fruit-development-sunflower': '7. Dezvoltarea fructelor',
  'germination-cereals': '0. Germinarea/dezvoltarea mugurilor',
  'germination-corn': '0. Germinarea/dezvoltarea mugurilor',
  'germination-fruits': '0. Germinarea/dezvoltarea mugurilor',
  'germination-grapes': '0. Germinarea/dezvoltarea mugurilor',
  'germination-legumes': '0. Germinarea/dezvoltarea mugurilor',
  'germination-rapeseed': '0. Germinarea/dezvoltarea mugurilor',
  'germination-sorghum': '0. Germinarea/dezvoltarea mugurilor',
  'germination-soybeans': '0. Germinarea/dezvoltarea mugurilor',
  'germination-sugar-beet': '0. Germinarea/dezvoltarea mugurilor',
  'germination-sunflower': '0. Germinarea/dezvoltarea mugurilor',
  'grams': 'gr',
  'harvest_year': 'Anul recoltei',
  'hectares': 'Ha',
  'hectoliters-per-hectare': 'hl/ha',
  'images': 'Fotografii',
  'inches': 'in',
  'inflorescence-cereals': '5. Apariția inflorescenței / spicului',
  'inflorescence-corn': '5. Apariția inflorescenței / spicului',
  'inflorescence-fruits': '5. Apariția inflorescenței / spicului',
  'inflorescence-grapes': '5. Apariția inflorescenței / spicului',
  'inflorescence-legumes': '5. Apariția inflorescenței / spicului',
  'inflorescence-rapeseed': '5. Apariția inflorescenței / spicului',
  'inflorescence-sorghum': '5. Apariția inflorescenței / spicului',
  'inflorescence-sunflower': '5. Apariția inflorescenței / spicului',
  'kilograms': 'kg',
  'kilograms-per-hectare': 'kg/ha',
  'leaf-development-cereals': '1. Dezvoltarea frunzelor',
  'leaf-development-corn': '1. Dezvoltarea frunzelor',
  'leaf-development-fruits': '1. Dezvoltarea frunzelor',
  'leaf-development-grapes': '1. Dezvoltarea frunzelor',
  'leaf-development-legumes': '1. Dezvoltarea frunzelor',
  'leaf-development-rapeseed': '1. Dezvoltarea frunzelor',
  'leaf-development-sorghum': '1. Dezvoltarea frunzelor',
  'leaf-development-soybeans': '1. Dezvoltarea frunzelor',
  'leaf-development-sugar-beet': '1. Dezvoltarea frunzelor',
  'leaf-development-sunflower': '1. Dezvoltarea frunzelor',
  'loss_cause': 'Cauza pierderii',
  'meters': 'm',
  'ounces': 'oz',
  'percent': '%',
  'pln': 'zł',
  'policy_id': 'Număr contract',
  'policy_number': 'Număr contract',
  'ripening-cereals': '8. Coacerea sau maturitatea fructelor sau semințelor',
  'ripening-corn': '8. Coacerea sau maturitatea fructelor sau semințelor',
  'ripening-fruits': '8. Coacerea sau maturitatea fructelor sau semințelor',
  'ripening-grapes': '8. Coacerea sau maturitatea fructelor sau semințelor',
  'ripening-legumes': '8. Coacerea sau maturitatea fructelor sau semințelor',
  'ripening-rapeseed': '8. Coacerea sau maturitatea fructelor sau semințelor',
  'ripening-sorghum': '8. Coacerea sau maturitatea fructelor sau semințelor',
  'ripening-soybeans': '8. Coacerea sau maturitatea fructelor sau semințelor',
  'ripening-sunflower': '8. Coacerea sau maturitatea fructelor sau semințelor',
  'senescence-fruits': '9. Senescență, începutul stării latente',
  'senescence-grapes': '9. Senescență, începutul stării latente',
  'senescence-sorghum': '9. Senescență, începutul stării latente',
  'senescence-soybeans': '9. Senescență, începutul stării latente',
  'senescence-sunflower': '9. Senescență, începutul stării latente',
  'side-shoot-cereals': '2. Formarea lăstarilor laterali/înfrățire',
  'side-shoot-sorghum': '2. Formarea lăstarilor laterali/înfrățire',
  'square-centimeters': 'cm²',
  'square-feet': 'ft²',
  'square-inches': 'in²',
  'square-meters': 'm²',
  'thousand-kernel-weight-grams': 'Greutatea la 1000 de boabe (g)',
  'bags': 'sc',
  'tons-per-acre': 't/ac',
  'tons-per-hectare': 't/ha',
  'units-per-acre': '/ac',
  'units-per-ft2': '/ft²',
  'units-per-hectare': '/ha',
  'units-per-m2': '/m²',
  'usd': '$',
  'user-admin-static-data': 'Date despre țară',
  'user_group': 'Portofoliu',
  'vegetative-growth-cereals': '3. Alungirea tulpinii/creșterea rozetei',
  'vegetative-growth-corn': '3. Alungirea tulpinii/creșterea rozetei',
  'vegetative-growth-fruits': '3. Alungirea tulpinii/creșterea rozetei',
  'vegetative-growth-legumes': '3. Alungirea tulpinii/creșterea rozetei',
  'vegetative-growth-rapeseed': '3. Alungirea tulpinii/creșterea rozetei',
  'vegetative-growth-sorghum': '3. Alungirea tulpinii/creșterea rozetei',
  'vegetative-growth-soybeans': '3. Alungirea tulpinii/creșterea rozetei',
  'vegetative-growth-sugar-beet': '3. Alungirea tulpinii/creșterea rozetei'
} as const;

export default function t(k: I18nSimpleKey|I18nParametric): string {
  if (typeof k == 'string' || typeof k == 'boolean') { return msgs[k]; }
  if (k === null || k == undefined) { console.error('t called with null or undefined!'); return '(∅)'; }
  switch (k.type) {
    case 'AddFarmTimeseriesMaxLimitError': return `Ați atins limita de ${k.count} ferme stocate în cache offline. Vă rugăm să ștergeți unul pentru a continua.`;
    case 'AdjusterSampledFarm': return `${k.added_by} a eșantionat ferma ${k.farm_name}`;
    case 'AllocateRemainingHarvestArea': return `Puteți aloca o altă ${intl_num_2(k.remainingArea)}` + t(k.unit) + `.`;
    case 'AreaIsKm2': return `Această zonă are ${intl_num(k.area)}km² (aproximativ ${intl_int(k.mb)}MB).`;
    case 'CurFieldPolicyDesc': return `Contract curent pentru parcela: ${k.policy_number}`;
    case 'CurPortfolio': return `Portofoliu: ${k.user_group}`;
    case 'DeleteAlreadyAssignedVisitWarning': return `Încercați să ștergeți o vizită care este atribuită și altor ${k.count} LA.`;
    case 'DeletedUserX': return `Utilizatorul „${k.user_name}” a fost șters`;
    case 'DistKmAway': return `${intl_num(k.dist_km)}km distanță`;
    case 'DownloadExplain': return `Selectați o zonă pentru a o descărca și utiliza offline. Toate detaliile de pe hartă vor fi descărcate, la toate nivelurile de focalizare. Suprafață maximă  ${intl_int(k.limit_km_2)}km². Puteți descărca mai multe zone odată, pentru utilizare offline.`;
    case 'DuplicateFieldId': return `Parcela ${k.external_field_id} a fost deja adăugată.`;
    case 'DuplicateSubplotWarning': return `Există deja un ID subplot: ${k.external_harvest_id} pentru această fermă și Anul recoltei. Pentru a adăuga un eșantion la acesta, vă rugăm să schimbați selecția de pe pagina anterioară sau să alegeți un alt ID de subplot.`;
    case 'EarsDetectedCount': return `Au fost detectate ${k.count} urechi`;
    case 'ErrorDeletingUserXY': return `Eroare la ștergerea „${k.user_name}”: ${k.error_message}`;
    case 'EstimatedYieldOverrideInfo': return `Randamentul estimat a fost anulat ultima dată pe ${k.date}. Rețineți că această modificare înlocuiește randamentul estimat agregat din eșantioanele de mai jos.`;
    case 'EstimatedYieldValueUnit': return `Estimat: ${intl_num_2(k.val)}` + t(k.unit);
    case 'ExistingHarvestContainsSamples': return `Recolta existentă conține ${k.count} eșantion(e).`;
    case 'ExoCropUnsupported': return `În prezent, această funcție nu acceptă ` + t(k.crop) + `.`;
    case 'FeasibleYieldValueUnit': return `Fezabil: ${intl_num_2(k.val)}` + t(k.unit);
    case 'FieldFormPickedOneOfMany': return `Atenție: acest eșantion va fi adăugat numai la primul dintre ${intl_int(k.numFields)} adăugate.`;
    case 'FileSizeTooBig': return `Acest fișier este prea mare. Vă rugăm să selectați un fișier mai mic de ${k.file_size}MB.`;
    case 'ImageLocationTooFar': return `Imaginea #${k.imageIdx} a fost luată ${intl_num(k.distanceMeters)}` + t(k.unit) + ` din locația eșantionului.`;
    case 'InsuredYieldValueUnit': return `Asigurat: ${intl_num_2(k.val)}` + t(k.unit);
    case 'LossValue': return `Pierdere: ${intl_num(k.yield_loss_pct)}%`;
    case 'LossesMismatchWarning': return `Suma pierderilor este prea mare: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` peste estimare.`;
    case 'LossesToAllocate': return `Pierderi de alocat: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` din ${intl_num_2(k.total)}` + t(k.unit) + ` pierdere totală.`;
    case 'NFarmsSelected': return `${intl_int(k.numFarms)} ferme selectate`;
    case 'NPoliciesSelected': return `${intl_int(k.numPolicies)} contracte selectate`;
    case 'NoAppInstalledToViewFile': return `Nu este instalată nicio aplicație pentru a vedea ${k.file_name}`;
    case 'NoDeleteClaim': return `Înainte de a șterge această revendicare, trebuie să ștergeți vizitele sale ${intl_int(k.visitCount)}.`;
    case 'NoDeleteFarm': return `Înainte de a șterge această fermă, trebuie să ștergeți câmpurile ${intl_int(k.fieldCount)}, eșantioanele ${intl_int(k.sampleCount)} și revendicările ${intl_int(k.claimCount)}.`;
    case 'NoDeleteField': return `Înainte de a șterge acestă parcela, trebuie să ștergeți cele ${intl_int(k.sampleCount)} eșantioane ale sale.`;
    case 'NoDeleteHarvest': return `Înainte de a șterge acestă campanie, trebuie să ștergeți ${intl_int(k.sampleCount)} eșantioane.`;
    case 'NoDeletePolicy': return `Înainte de a șterge această politică, trebuie să ștergeți revendicările ei ${intl_int(k.claimCount)}.`;
    case 'NumPhotos': return `${intl_int(k.num_photos)} fotografii`;
    case 'PasswordResetSent': return `Un e-mail de resetare a parolei a fost trimis la ${k.email}`;
    case 'PlantDensityValueUnit': return `Densitatea plantelor: ${intl_num(k.val)}` + t(k.unit);
    case 'PolicyDesc': return `Contract ${k.policy_number}`;
    case 'ReportContainsTooManyFields': return `Acest raport este prea mare, cu ${intl_int(k.numFields)} parcele. Vă rugăm să selectați dintre datele vizitelor și tipurile de culturi.`;
    case 'ReportNoHarvests': return `Această fermă are ${intl_int(k.numFields)} parcele, dar nu are informații despre culturi pe care să le raporteze`;
    case 'ResetMsg': return `Un e-mail cu un link de resetare a fost trimis la ${k.email}`;
    case 'SampleAbrv': return `${intl_int(k.count)} s.`;
    case 'SampleLocationTooFar': return `Locația eșantionului este ${intl_num_2(k.val)}` + t(k.unit) + ` față de locația dvs. de pornire. Dacă doriți să vă folosiți locația, apăsați „Folosește locația mea” de mai sus.`;
    case 'SampleYieldWarning': return `Completați fie ${k.column1}, fie ${k.column2}, dacă este posibil.`;
    case 'SearchAddTipDesc': return `Dacă doriți să căutați în funcție de mai multe criterii (de exemplu: recolte de grâu în portofoliul ${k.firstUserGroup}), puteți apăsa pe pictograma ⊕ pentru a adăuga un criteriu suplimentar.`;
    case 'SearchExampleDescUserGroup': return `Tastați un nume de portofoliu (de exemplu, ${k.firstUserGroup}) pentru a afișa toate articolele din acel portofoliu`;
    case 'SearchFoundCount': return `S-au găsit ${intl_int(k.farmCount)} ferme, ${intl_int(k.fieldCount)} parcele și ${intl_int(k.sampleCount)} eșantioane.`;
    case 'SearchInfoText': return `Puteți găsi ferme, parcele, eșantioane și contracte căutându-le aici. Dacă doriți să căutați în funcție de mai multe criterii (de exemplu: recolte de grâu în portofoliul ${k.firstUserGroup}), puteți apăsa pe pictograma ⊕. Fiecare căutare va acționa apoi ca un filtru care restrânge rezultatele. Consultați exemplele de mai jos pentru câteva modalități de căutare.`;
    case 'SearchResultAddedBy': return `eșantioane adăugate de ${k.id}`;
    case 'SearchResultAddress': return `Centrați harta pe ${k.address}`;
    case 'SearchResultCropCondition': return `eșantioane de cultură cu starea ${k.id}`;
    case 'SearchResultFarm': return `fermă ${k.farmDesc}`;
    case 'SearchResultHarvestCrop': return `articole pentru ${k.id}`;
    case 'SearchResultHarvestSeason': return `articole pentru campania de ` + t(k.id);
    case 'SearchResultHarvestYear': return `articole pentru anul ${k.id}`;
    case 'SearchResultUserGroup': return `Portofoliu ${k.id}`;
    case 'SelectCount': return `${k.count} selectate`;
    case 'SortBy': return `Sortați ${k.column} ` + t(k.id);
    case 'SureAddAnotherHarvest': return `Sigur doriți să adăugați o altă recoltă la acestă parcela, pe lângă ${k.harvestDesc}?`;
    case 'SureDeleteFile': return `Sigur doriți să ștergeți ${k.file_name}?`;
    case 'SureDeletePolicy': return `Sigur doriți să ștergeți acest contract? Referința la acest contract va fi eliminată din ${intl_int(k.fieldCount)} parcele și din ${intl_int(k.sampleCount)} eșantioane, dar acestea nu vor fi șterse.`;
    case 'SureRemoveFarm': return `Sigur doriți să ștergeți această fermă și cele ${intl_int(k.fieldCount)} parcele ale sale?`;
    case 'SureRemoveSelectedOfflineFarms': return `Sigur doriți să eliminați accesul offline pentru ${k.count} ferme selectate?`;
    case 'TelepacReportEmail': return `Un raport de import v-a fost trimis la ${k.email}, îl puteți descărca și aici.`;
    case 'TotalAffectedAreaHarvest': return `Suprafața totală afectată pentru această recoltă va fi de ${intl_num_2(k.newArea)}` + t(k.unit) + ` pe o suprafață de recoltare de ${intl_num_2(k.harvestArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt': return `Zona actuală afectată (eșantioane din ultimele două săptămâni) pentru această recoltă este: ${intl_num_2(k.curArea)}` + t(k.unit) + `.`;
    case 'TotalAffectedAreaHarvestPrompt2': return `Acestea sunt toate zonele afectate pentru această recoltă: ${k.arr}.`;
    case 'TotalLossPct': return `Pierderi totale: ${intl_num(k.totalLossPct)}%`;
    case 'TotalSubplotAreaBiggerThanField': return `Suprafața totală a terenului secundar (${intl_num_2(k.total_subplot_area)} ha) este mai mare decât suprafața câmpului (${intl_num_2(k.field_area)} ha).`;
    case 'UpdateHarvestAreaMsg': return `Aceasta va actualiza Suprafaţă cultivată recoltei curente (${k.desc}) la ${intl_num_2(k.val)}` + t(k.unit) + `.`;
    case 'UpgradeFieldHarvest': return `Acest câmp are în prezent o recoltă de ${k.originalHarvest}, care va fi înlocuită cu o recoltă de ${k.upgradedHarvest}, pentru a corespunde contractului.`;
    case 'WarnHarvestAlreadyInUse': return `Salvarea modificărilor aduse acestei recolte existente ar afecta ${intl_int(k.num_samples)} mostre deja existente.`;
    case 'WarningDupePolicyNumber': return `Avertisment: există un alt contract cu numărul ${k.policy_number}`;
    case 'YieldMismatchWarning': return `Nu toate daunele sunt contabilizate: ${intl_num_2(k.deltaVal)}` + t(k.unit) + ` lipsă.`;
    case 'YourAreGoingToDeleteUsers': return `Veți șterge ${intl_int(k.num_users)} utilizatori:`;
    case 'downloadingLayers': return `Se descarcă ${intl_int(k.count)} straturi pentru utilizare offline.`;
    case 'uploadingImages': return `Fotografii se încarcă în baza de date (stânga ${intl_int(k.left)}).`;
    case 'AreaUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'AreaUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
    case 'FarmDesc': return `${k.farm_name}, ${k.address}`;
    case 'FarmDesc_reference': return `${k.farm_name}, ${k.address} (${k.external_farm_id})`;
    case 'SampleDesc': return `${k.sample_date}: ${intl_num(k.estimated_yield_val)}` + t(k.estimated_yield_unit) + ` ` + t(k.crop_condition);
    case 'ValueUnit': return `${intl_num(k.val)}` + t(k.unit);
    case 'ValueUnitRange': return `${intl_num(k.min_val)}-${intl_num(k.max_val)}` + t(k.unit);
    case 'YieldUnit': return `${intl_num_2(k.val)}` + t(k.unit);
    case 'YieldUnit4': return `${intl_num_4(k.val)}` + t(k.unit);
  }
}